import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../cart.service';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  
  productIdFromRoute!:number;
  productDetailsDone!: boolean;

  constructor( 
    private route: ActivatedRoute,
    public cartService: CartService,    
    private router: Router, 
    public commonService: CommonService, ) { 
      
      this.route.paramMap.subscribe(params => {                  
        const routeParams = this.route.snapshot.paramMap;
        this.productIdFromRoute = Number(routeParams.get('productId')); 
      },error=> {  });

    }

  ngOnInit(): void {
  }

  getProductDetailsIsDone(done: boolean) {
    this.productDetailsDone=done;
  }

  pageIsDone() {
    return this.productDetailsDone;
  }

}
