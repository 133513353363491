import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { productType,currencyType } from '../models';
import { CommonService } from '../common.service';
import { ScreenService } from '../screen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit {

  @Input() paramCurrency: currencyType=this.commonService.setEmptyCurrencyData();   
  @Output() searchTextEmit = new EventEmitter<string>(); 
  searchControl = new FormControl();
  options: productType[] = [];
  filteredOptions!: Observable<productType[]>;
  filterBasic:string='';
  filterNext:string='';  
  goToProductId:number=0;
  infoDialogHeader: string ='';
  infoDialogContent: string ='';

  constructor(public infoDialog: MatDialog,
              public commonService: CommonService,
              public screenService: ScreenService, 
              private router: Router,               ) {

                

 }   

  ngOnInit(): void {  
    this.searchControl.valueChanges.subscribe(value => {
      const filterValue = value.toLowerCase();
      if (filterValue.length>=3) {  this.filterNext=filterValue.substring(0,3); }
      if ((filterValue.length==3) && (this.filterNext!=this.filterBasic)) {     
        this.commonService.getProducts(0,this.filterNext).subscribe((products: productType[]) => {                   
          var o: productType[] = []; 
          products.forEach(function(item){         
            o.push(item);  
          });     
          this.options=o;     
          this.filterBasic=this.filterNext;    
          
          this.filteredOptions = this.searchControl.valueChanges.pipe(    
            startWith(''),
            map(product =>   
              this.filter(product)
            )          
          );
        });      
      }  
      if (filterValue.length<3) {
        this.options.length=0;
        this.options=[];
        this.filterBasic='';  
        this.filterNext='';              
      }  
    });
  } 
  

  sendSearchText() {          
    var st:string=this.searchControl.value;
    if ( (!this.screenService.infoDialogIsOpen) && (st.length>0)) {         
      if (this.goToProductId==0) {
        if ((st.length>=3) && (st.length<=100)) {
          this.searchTextEmit.emit(st);            
          this.options.length=0;
          this.options=[];
          this.filterBasic='';
          this.filterNext='';
          this.searchControl.setValue('');
        } 
        else {
          this.infoDialogHeader='Tisztelt látogató! ';         
          this.infoDialogContent='<p>A keresendő kifejezés minimum 3, maximum 20 karakter lehet! </p';
          this.screenService.infoDialogIsOpen=true;         
          const dialogRef=this.infoDialog.open(InfoDialogComponent, { data: {header: this.infoDialogHeader, content: this.infoDialogContent} });   
          dialogRef.afterClosed().subscribe(result => {      
            var that = this;
            setTimeout(function(){
              that.screenService.infoDialogIsOpen=false;
            },100);          
          }); 
        }           
      }
      else {     
        this.options.length=0;
        this.options=[];
        this.filterBasic='';
        this.filterNext='';           
        this.searchControl.setValue('');
        this.router.navigate(['/products',this.goToProductId]);               
      }  
      this.goToProductId=0;    
    }  
  }    
  
  keyUpEnter() {        
    if (!this.screenService.infoDialogIsOpen) {        
      this.sendSearchText();
    }    
  }

  clearSearchControlValue (product:productType) { 
    this.goToProductId=product.id;
    this.searchControl.setValue('');
  }

  private filter(value: string): productType[] {        
      const filterValue = value.toLowerCase();  
      return this.options.filter(option => (option.name+' '+option.sku).toLowerCase().includes(filterValue));            
  }

}
