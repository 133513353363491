  <div class="top-logo-container"> 
    <a  [routerLink]="['/']">
      <h1 style="color: #3f51b5; font-weight: 900; font-size: 33px;">
        <mat-icon class="top-logo-icon" color="accent">important_devices</mat-icon>
        <span style="margin-left: 14px;">{{this.commonService.title}}</span>
        <span style="color: #ff4081; margin-left: 5px; opacity: 0.8;">{{this.commonService.title1}}</span>
      </h1>
    </a>
  </div> 
  
  
  
  <div class="top-cart-container" (mouseleave)="this.showCartMenu=false" (mouseover)="this.showCartMenu=true">     
  
    <button (click)="this.showCartMenu=false" (mouseover)="this.showCartMenu=true" mat-flat-button color="accent" routerLink="/cart" style="margin-right: 0px; border-radius: 3px;" [ngStyle]="{'width': screenService.screenWidth>=870 ? '180px' : '100px' }" >
      <i class="material-icons" style="padding-right: 10px;">shopping_cart</i>
      <!-- <span *ngIf="!(screenService.isHandset$ | async) && !(screenService.isTabletset$ | async)"> -->
      <span *ngIf="screenService.screenWidth>=870"> 
        <span *ngIf="cartService.getCartSumValue()==0">A kosár üres</span>
        <span *ngIf="cartService.getCartSumValue()!=0">        
          {{ (cartService.getCartSumValue()) | mycurrency:this.cartService.selectedCurrency }}
        </span>    
      </span>
      <!-- <span *ngIf="(screenService.isHandset$ | async) || (screenService.isTabletset$ | async)"> -->
      <span *ngIf="screenService.screenWidth<870"> 
        <span *ngIf="cartService.getCartSumValue()==0">üres</span>
        <span *ngIf="cartService.getCartSumValue()!=0">+{{cartService.items.length}}</span>      
      </span>
    </button>   
    
    

    <app-cart-menu class="cart-menu-container" (isCloseEmit)="getCartMenuIsClose($event)" [paramIsVisible]="(this.showCartMenu) && (this.cartService.cartComponentIsActive===false) && (this.screenService.screenWidth>477)" (mouseleave)="this.showCartMenu=false;">
    </app-cart-menu>
  
  </div>
  
  
  


  <div class="top_contact_container"> 
    <div style="color: #3f51b5; font-size: 14px; line-height: 1; font-weight: 500;">
      <span style=" color: #3f51b5; font-size: 20px; font-weight: 300;">☎ +36(52)427498</span>
      <br>
      ✉ shop@notebookbolt.hu
    </div>
  </div>


  <div class="product_search_field"> 
    <app-product-search [paramCurrency]="this.cartService.selectedCurrency" (searchTextEmit)="getSearchText($event)"></app-product-search>
  </div>
  
  
