import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductListSalesComponent } from './product-list-sales/product-list-sales.component';
import { ProductComponent } from './product/product.component';
import { CartComponent } from './cart/cart.component';
import { ShippingComponent } from './shipping/shipping.component';
import { PaymentComponent } from './payment/payment.component';
import { CategoryProductListComponent } from './category-product-list/category-product-list.component';
import { HomeComponent } from './home/home.component';
import { ProductSearchResultComponent } from './product-search-result/product-search-result.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', component:  HomeComponent },
  { path: 'sales', component:  ProductListSalesComponent },
  { path: 'products/:productId', component: ProductComponent },  
  { path: 'categories/0', component: HomeComponent }, 
  { path: 'categories/:categoryId', component: CategoryProductListComponent },    
  { path: 'cart', component: CartComponent },
  { path: 'shipping', component: ShippingComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'search/:searchText', component: ProductSearchResultComponent },
  { path: 'contact', component:  ContactComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
