import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { productType, categoryType, manufacturerType, sortByType, inStockType, currencyType} from '../models';
import { CommonService } from '../common.service';
import { FormControl } from '@angular/forms';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { formatNumber} from '@angular/common';



@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',  
  styleUrls: ['./product-list.component.css']  
})

export class ProductListComponent implements OnInit, OnChanges {
  
  @Input() paramCategoryId!: number;
  @Input() paramSearchText: string='';    
  @Input() paramCurrency: currencyType=this.commonService.setEmptyCurrencyData();  
  @Output() isDoneEmit = new EventEmitter<boolean>();
  isDone!:boolean;

  products: productType[] = [];
  productsFiltered: productType[] = [];
  manufacturers: manufacturerType[] = [];  
  sortByTypes: sortByType[] = [];
  inStockTypes: inStockType[] = [];
  categoryDetail:categoryType=this.commonService.setEmptyCategoryData();

  priceMinValue: number = 10000;
  priceMaxValue: number = 1500000;
  priceSliderOptions: Options = {
    floor: this.priceMinValue,
    ceil: this.priceMaxValue,
    step: this.priceMinValue,    
    showTicks: false,       
  }; 
    
  sortByInput = new FormControl();
  manufacturedByInput = new FormControl();
  inStockByInput = new FormControl();  
  priceRangeInput = new FormControl();

  sortBySelectedValue: number=0;
  manufacturedBySelectedValue: number[] = [];
  inStockSelectedValue: number=0;
  priceRangeSelectedValue: number[] = [];

  showFilter:boolean=false;

  constructor(private cartService: CartService,
              public screenService: ScreenService,
              public _snackBar: MatSnackBar,
              private router: Router,                            
              public commonService: CommonService,              
              ) 
              { 

                this.sortByInput.setValue('');
                this.sortByTypes=[];
                this.sortByTypes.length=0;
                this.commonService.getSortByTypes().subscribe((sortByTypes: sortByType[]) => {            
                  this.sortByTypes = sortByTypes;                      
                });
                
                this.inStockByInput.setValue('');
                this.inStockTypes=[];
                this.inStockTypes.length=0;
                this.commonService.getInStockTypes().subscribe((inStockTypes: inStockType[]) => {            
                  this.inStockTypes = inStockTypes;                      
                });

               
              }
 
  openSnackBar(message: string, action: string) {
     const snackBarRef=this._snackBar.open(message, action, {duration: 3000,});
     snackBarRef.onAction().subscribe(() => {
       this.router.navigate(['cart']);
     });
  }

  setRangeSliderOptions() {
    var loc:string='hu';//this.paramCurrency.locale;
    var sym:string=this.paramCurrency.symbol;
    var swi:number=this.paramCurrency.switchValue;
    var minV:productType=this.commonService.setEmptyProductData();  
    if (this.products.length>0) {
      minV=this.products.reduce(function (p, v) {
        return ( p.price < v.price ? p : v );
      });
    }
    var maxV:productType=this.commonService.setEmptyProductData();  
    if (this.products.length>0) {
      maxV=this.products.reduce(function (p, v) {
        return ( p.price > v.price ? p : v );
      });
    }
    var s:number=10000;
    if ((minV.price/swi)<10000) {
      s=1000;
    }
    if ((minV.price/swi)<1000) {
      s=100;
    }
    if ((minV.price/swi)<100) {
      s=10;
    }
    this.priceMinValue=(Math.floor((minV.price/swi)/s)*s);
    this.priceMaxValue=(Math.ceil((maxV.price/swi)/s)*s);  
    this.priceSliderOptions = {
      floor: this.priceMinValue,
      ceil: this.priceMaxValue,
      step: s, 
      showTicks: false,   
      translate: (value: number, label: LabelType): string => {
        switch (label) {        
          case LabelType.Low:
            return '<span style="font-weight: 500; color: #1976d2;">Min:</span> <span style="font-weight: normal; ">' + formatNumber(value, loc , '0.0-0')+' '+sym+'</span>';
          case LabelType.High:
            return '<span style="font-weight: 500; color: #ff4081;">Max:</span> <span style="font-weight: normal; ">' + formatNumber(value, loc, '0.0-0')+' '+sym+'</span>';
          default:
            return '';
        }            
      } 
    };       
  } 

  ngOnChanges(ch: SimpleChanges) {          
    var currencyChange:boolean=false;   
    if ((ch.paramCurrency!==undefined)) { 
      if (ch.paramCurrency.previousValue!==undefined) {        
          currencyChange=(ch.paramCurrency.previousValue!==ch.paramCurrency.currentValue);        
      }
    }    
    if ((!currencyChange) ) {    
      this.categoryDetail=this.commonService.setEmptyCategoryData(); 
      this.products=[];
      this.products.length = 0;    
      this.productsFiltered=[];
      this.productsFiltered.length = 0;         
      this.isDone=false;
      this.isDoneEmit.emit(false);
      this.commonService.getCategoryDetails(this.paramCategoryId).subscribe((resultCategoryDetail: categoryType) => {            
        this.commonService.getProducts(resultCategoryDetail.id,this.paramSearchText).subscribe((products: productType[]) => {        
          this.categoryDetail = resultCategoryDetail;     
          this.products = products;          
          this.productsFiltered = this.products;              
          this.isDoneEmit.emit(true);
          this.isDone=true;
          this.setRangeSliderOptions();    
        });
      });    
      this.manufacturedBySelectedValue = [];
      this.manufacturers=[];
      this.manufacturers.length = 0;     
      this.commonService.getCategoryManufacturers(this.paramCategoryId).subscribe((manufacturers: manufacturerType[]) => {            
        this.manufacturers = manufacturers;      
      });        
      this.sortBySelectedValue=0;
      this.inStockSelectedValue=0;
    } 
    else {
        this.setRangeSliderOptions();    
        this.onProductsFilter();  
     }    
  }

  ngOnInit(): void {       
     
  }
  
  addToCart(product:productType) {          
    this.cartService.addToCart(product,1);         
    this.openSnackBar(product.name+' termék a kosárba került!','Bevásárló kosár'); 
  }

  onSortBySelection() {       
    if (this.sortBySelectedValue==1) {
      this.productsFiltered.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); 
        var nameB = b.name.toUpperCase(); 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
       // ha egyenlőek a kiefejezések
       return 0;
      });
    }

    if (this.sortBySelectedValue==2) {
      this.productsFiltered.sort(function (a, b) {
        return a.price - b.price;
      });
    }

    if (this.sortBySelectedValue==3) {
      this.productsFiltered.sort(function (a, b) {
        return b.price - a.price;
      });
    }

    if (this.sortBySelectedValue==4) {
      this.productsFiltered.sort(function (a, b) {
        return b.id - a.id;
      });
    }    
  }  

  onProductsFilter() {
    //manufacturer filter
    var mf=this.manufacturedBySelectedValue;
    if (mf.length>0) {
      this.productsFiltered=this.products.filter(function isInMfList (a) {        
        return mf.includes(a.manufacturer.id);
      }); 
    }
    else {
      this.productsFiltered=this.products;
    }   
    //instock filter       
    if (this.inStockSelectedValue==2) {
      this.productsFiltered=this.productsFiltered.filter(function isInStock (a) {        
        return a.inStock>0;
      }); 
    }
    if (this.inStockSelectedValue==3) { 
      this.productsFiltered=this.productsFiltered.filter(function isLastInStock (a) {        
        return ((a.inStock>0) && (a.inStock<=5));
      }); 
    }
    if (this.inStockSelectedValue==4) {
      this.productsFiltered=this.productsFiltered.filter(function isJustOrder (a) {        
        return a.inStock<=0;
      }); 
    }    
    //price filter    
    var minV=this.priceMinValue;
    var maxV=this.priceMaxValue;    
    var swi:number=this.paramCurrency.switchValue;

    this.productsFiltered=this.productsFiltered.filter(function isInPriceRange (a) {   
        return (((a.price/swi)>=minV)  && ((a.price/swi)<=maxV));
    }); 

    this.onSortBySelection();
  }
  

}
