
 <div class="container" style="background-color: white; border-radius: 3px; box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;" >  

  
  <div *ngIf="this.productDetailsIsDone" >
    <app-breadcrumb [categoryId]="product.category.id"></app-breadcrumb>      
  </div>
  

  <div *ngIf="(product.medias.length>0) && (this.productDetailsIsDone)" class="product-detail-image-cont" >       
    <img class="product-detail-image" src="{{ selectedImagePath }}" (click)="showProductGallery(selectedImageIndex)" />     
    <div *ngIf="(product.medias.length>1)" style="text-align: center;">
      <img *ngFor="let medias of product.medias; let indexOfMedia=index;" class="product-img mini" style="padding: 4px;" src="{{ medias.file_url }}" (click)="changeSelectedImagePath( medias )" [ngStyle]="{'border': (selectedImageIndex==indexOfMedia)  ? '1px solid rgba(0,0,0,.24)' : '1px solid transparent'}"  />                                
    </div>  
  </div>    
  

  <div *ngIf="this.productDetailsIsDone" class="product-detail-desc">                     

     
      <mat-card-title class="product-details-name">
        <a [title]="product.name + ' részletek'" [routerLink]="['/products', product.id]">
          {{ product.name }}
        </a> 
      </mat-card-title>

      <mat-card-subtitle class="product-details-sku">                
        Cikkszám: {{product.sku}}            
      </mat-card-subtitle> 

      

      

      <mat-card-actions class="product-detail-actions">          
        <div style="margin-bottom: 15px; ">                           
          <div class="product-details-percent-container" *ngIf="product.discountPercent>0">
            <button mat-fab color="warn" [routerLink]="['/products', product.id]" style=" display: inline-block; margin-top: 0px; margin-bottom: 10px; " >
             -{{product.discountPercent}}%
            </button> 
          </div>
          <div class="product-details-price" >            
            <div *ngIf="product.discountPercent>0" >Eredeti ár: 
              <span style="margin-bottom: 0px; padding-bottom: 0px; line-height: 1; color:red;text-decoration:line-through">
                <span style="color:black">
                  {{ (product.origPrice) | mycurrency:paramCurrency }}
                </span>
              </span>
            </div>                               
            <h1 style="margin-top: 0px; margin-bottom: 0px; line-height: 1; font-weight: 500; font-size: 31px;">            
              {{ (product.price) | mycurrency:paramCurrency }}
            </h1>                      
            <div [ngStyle]="{'color': product.tax.mathMethod == '+%' ? 'red' : 'rgba(0,0,0,.54)' }" style=" font-size: 12px; letter-spacing: -0.4px; padding-top: 0px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;padding-left: 1px; line-height: 1;">
              {{product.tax.caseDescription}}
            </div>                   
            <div *ngIf="product.discountPercent>0" >Megtakarítás: {{((product.origPrice-product.price)) | mycurrency:paramCurrency }}</div>                               
          </div>         
          
          <div class="product-details-price-warn" *ngIf="product.discountPercent>0" >            
            ⚠ A termék akciós ára 
            <br>
            <span style="color:  #eeb5b1 #f44336; font-weight: 500; ">{{product.discountEndDate}}-ig,</span>
            <br>és a készlet erejéig érvényes!                       
          </div> 
          
          <div class="product-details-price-warn mobil" *ngIf="product.discountPercent>0" >            
            ⚠ A termék akciós ára
            <span style="color:  #eeb5b1 #f44336; font-weight: 500; ">{{product.discountEndDate}}-ig,</span>
            <br>
            és a készlet erejéig érvényes!                       
          </div> 
         
        </div>
      
        <div style=" color: rgba(0,0,0,.54);   font-size:14px;  display: inline-block; vertical-align: middle; width: 99%;"  >                          
          
          <div style=" width: 204px !important; display: inline-block !important; vertical-align: middle; padding-top: 0px;">
            <button  (click)="decQuantity()" style="border-radius: 3px; background-color: #82b2e2 !important; display: inline-block !important; cursor: pointer; height: 36px; top: -7px; position: relative; font-weight: 900;" >
              -
            </button>
            <mat-form-field appearance="outline"  style="width: 110px; display: inline-block !important; margin-left: 6px; margin-right: 6px;">
              <mat-label style="text-transform: none; color: #3f51b5;">Mennyiség</mat-label>
              
              <input               
                matInput        
                appOnlyPositiveNumbers                        
                [formControl]="quantityControl" 
                (keyup.enter)="addToCart(product)"   
                                    
                type="number"   
                placeholder=" "                                                                                        
                min="1"                
                max="999"     
                pattern="d+"
                step="1"                                                            
                style=" font-weight: 600; text-align: center; font-size: 15px; top: 10px !important; position: absolute;"
               
              >           
              <div matSuffix style="margin-right: 0px; display: inline-block !important; color: #3f51b5; font-size: 15px; line-height: 1; height: 10px; top: -9px !important; position: relative;">&nbsp;db</div>
              
            </mat-form-field>         
            <button  (click)="incQuantity()" style="border-radius: 3px; background-color: #3f51b5 !important; display: inline-block !important; cursor: pointer; height: 36px; top: -7px; position: relative; font-weight: 900;" >
              +
            </button>    
            
          </div> 
          
        
            <button mat-flat-button class="two-color-button large" [disabled]="!this.quantityIsValid"  (click)="addToCart(product)">
              <i class="material-icons" style="padding-right: 36px;">shopping_cart</i>
              <span style="padding-left: 9px;">Kosárba tesz</span>
            </button>
        
            <div class="error-text product-details-q">
              <span *ngIf="!this.quantityIsValid">
              A mennyiség csak 1000-nél kisebb pozitív egész szám lehet!
              </span>
            </div> 

        </div> 


        
        
      </mat-card-actions>     
      
      
      <mat-card-content style="color: rgba(0,0,0,.54); text-align: justify; display: inline-block; margin-top: 0px; padding-top: 20px; border-radius: 3px;">           
        
        <mat-card-subtitle style="font-weight: 400; font-size: 16px; margin-bottom: 25px;  display: block;"  [ngStyle]="{'color': (product.inStock>5)  ? '#9ccc65' :(product.inStock<=0)  ? 'gray' : '#f44336'}">
          <mat-icon style="vertical-align: text-bottom;">assessment</mat-icon>
          {{product.stockInfo}}
        </mat-card-subtitle>
        
        <mat-icon style="display: inline-block !important; vertical-align: text-bottom;">description</mat-icon>
        <h3 style="display: inline-block !important; margin-bottom: 10px; font-weight: 500; ">
            &nbsp;Leírás  
        </h3>          
        <div>      
          
        </div>
        <p class="p-detail" *ngIf="product.description">
          {{ product.description }}            
        </p>      
        <p class="p-detail" *ngIf="!product.description">
          Nincs elérhető leírás!
        </p>   
        
        <div style="margin-bottom: 5px;">          
          
          <mat-icon style="display: inline-block !important; vertical-align: text-bottom;">settings</mat-icon>                
          <h3 style="display: inline-block; font-weight: 500; vertical-align: middle; margin-bottom: 5px; ">              
            &nbsp;Gyártó 
          </h3>  

          <table style="padding-left: 26px;">
            <tr style="width: 100%; vertical-align: top;" >
              <td style="width: 300px">
                <div *ngIf="product.manufacturer.medias.length==0" >{{product.manufacturer.name}}</div>
                <img class="manufacturer-img" *ngIf="product.manufacturer.medias.length>0"  src="{{ product.manufacturer.medias[0].file_url }}"  />   
              </td>
              <td>                
              </td>
            </tr>
          </table>          

        </div> 

        <div *ngIf="product.customFieldGroups.length>0">        
                          

          <div>          
            <div *ngFor="let customFieldGroup of product.customFieldGroups; let indexOfgroup=index;" style="margin-bottom: 10px; vertical-align: top; " >                           
              <mat-icon style="display: inline-block !important; vertical-align: text-bottom;">{{customFieldGroup.icon}}</mat-icon>       
               <h3 style="display: inline-block !important; font-weight: 500; margin-bottom: 5px;">&nbsp;{{customFieldGroup.name}}</h3>
               <table class="p-detail-customtable">
               
               <div *ngIf="this.screenService.screenWidth>=467">
                <tr *ngFor="let customField of customFieldGroup.customFields; let indexOfField=index;" [ngStyle]="{'background': indexOfField % 2 == 0 ? 'linear-gradient(to right, rgba(63, 81, 181, 0.05), rgba(63, 81, 181, 0))' : 'none' }"  style="width: 100%; vertical-align: top; " >
                 <td class="p-detail-customtable-tdlabel">{{customField.name}}</td><td class="p-detail-customtable-tdvalue" >{{customField.value}}</td>
                </tr>
               </div> 

               <div *ngIf="this.screenService.screenWidth<467">
               <div *ngFor="let customField of customFieldGroup.customFields; let indexOfField=index;">
                <tr [ngStyle]="{'background': indexOfField % 2 == 0 ? 'linear-gradient(to right, rgba(63, 81, 181, 0.1), rgba(63, 81, 181, 0))' : 'none' }"  style="width: 100%;  display: block; " >
                 <td class="p-detail-customtable-tdlabel">{{customField.name}}:</td>                 
                </tr>
                <tr [ngStyle]="{'background': indexOfField % 2 == 0 ? 'linear-gradient(to right, rgba(63, 81, 181, 0.05), rgba(63, 81, 181, 0))' : 'none' }"  style="width: 100%;  display: block; " >                  
                  <td class="p-detail-customtable-tdvalue" >{{customField.value}}</td>
                 </tr>
               </div> 
               </div>
               
              </table>
            </div>  
          </div>

        </div>

      </mat-card-content>    

  </div>          


</div>

