import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  
  
  numbers:number[] = [1,4,2,1,2,4,5,6,6,6,9,1,5,9,11];
  oddNumbers = this.numbers.filter((n, i) => (this.numbers.indexOf(n) === i) && (n & 1));
  evenNumbers = this.numbers.filter((n, i) => (this.numbers.indexOf(n) === i) && !(n & 1));
  

  constructor() { }

  ngOnInit(): void {

    console.log(this.oddNumbers);
    console.log(this.evenNumbers);
  }

}
