import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { paymentType } from '../models';
import { CommonService } from '../common.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  loadTable=true;  
  displayedColumns: string[] = ['id', 'type'];  
  payments = new MatTableDataSource<paymentType>(); 

  @ViewChild(MatPaginator)
  paginator!: MatPaginator; 
  @ViewChild(MatSort)
  sort!: MatSort;

  ngAfterViewInit() {
    this.payments.paginator = this.paginator;
    this.payments.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.payments.filter = filterValue.trim().toLowerCase();

    if (this.payments.paginator) {
      this.payments.paginator.firstPage();
    }
  }

  constructor(public commonService: CommonService) { }

  

  ngOnInit(): void {
   
    this.commonService.getPayments().subscribe((paymantlist: paymentType[]) => {
      this.payments.data = paymantlist;
      this.loadTable=false;
    });

  }

}
