import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonService } from '../common.service';
import { ScreenService } from '../screen.service';
import { categoryType } from '../models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})

export class BreadcrumbComponent implements OnInit, OnChanges {

  parentCategories: categoryType[] = [];
  breadcrumbIsDone = false;
  @Input() categoryId: any;

  constructor(public commonService: CommonService,
              public screenService: ScreenService,) {  }

  ngOnChanges() {   
    if (this.categoryId == 0) {
      this.breadcrumbIsDone = true;
      this.parentCategories = [];
    }
    else {
      this.breadcrumbIsDone = false;
      this.commonService.getCategoryBreadcrumbItems(this.categoryId).subscribe((categories: categoryType[]) => {
        this.parentCategories = categories;
        this.breadcrumbIsDone = true;
      });
    }
  }

  ngOnInit(): void {  }



}
