import { Component, OnInit, Input,OnChanges, Output, EventEmitter, SimpleChanges, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { productType, mediaType, currencyType } from '../models';
import { CommonService } from '../common.service';
import { Gallery } from 'angular-gallery';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit, OnChanges {  
  product:productType=this.commonService.setEmptyProductData();  
 

  selectedImagePath!:string;  
  selectedImageIndex!:number;

  
  @Input() paramProductId!: number;     
  @Input() paramCurrency: currencyType=this.commonService.setEmptyCurrencyData(); 
  @Output() isDoneEmit = new EventEmitter<boolean>();

  productDetailsIsDone:boolean=false;

  notFixed!: boolean;
  topPosToStartShowing = 700;  
   
  quantityControl = new FormControl();
  infoDialogHeader: string ='';
  infoDialogContent: string ='';
  
  quantityIsValid:boolean=false;

  @HostListener('window:scroll') 
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.notFixed = true;
    } else {
      this.notFixed = false;      
    }    
    
    
  }

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    public screenService: ScreenService,
    public _snackBar: MatSnackBar,
    private router: Router,    
    public commonService: CommonService,
    private gallery: Gallery,
    public infoDialog: MatDialog,  
  ) 
  { 
  
  }


  quantityFocusOut(product:productType) {
    if ((Number.isInteger(this.quantityControl.value)) && (this.quantityControl.value>0)  && (this.quantityControl.value<=1000)) {
      
    }  
    else {      
      this.quantityControl.setValue(1);
    } 
  }

  changeSelectedImagePath( selectedMedia:mediaType,  ) {
    this.selectedImagePath=selectedMedia.file_url;
    var medias:string[]=[];
    medias=this.product.medias.map(m=>m.file_url);
    this.selectedImageIndex=medias.indexOf(this.selectedImagePath);
  }  


  showProductGallery(index: number ) {       
      let ims:any[]=[];
      this.product.medias.forEach(function(item){         
        ims.push({path: item.file_url});  
      });
      let prop:any = {
        images: [],
        index
      };        
      prop.images=ims;              
      this.gallery.load(prop);      
  }  



  openSnackBar(message: string, action: string) {
    const snackBarRef=this._snackBar.open(message, action, {duration: 3000,});
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['cart']);
     });
  }

  ngOnChanges(ch: SimpleChanges) { 
    var currencyChange:boolean=false;   
    if ((ch.paramCurrency!==undefined)) { 
      if (ch.paramCurrency.previousValue!==undefined) {        
          currencyChange=(ch.paramCurrency.previousValue!==ch.paramCurrency.currentValue);        
      }
    }    
    if ((!currencyChange) ) {               
      this.productDetailsIsDone=false;
      this.isDoneEmit.emit(false);
      this.commonService.getProductDetails(this.paramProductId).subscribe((productDetail: productType) => {
        this.product = productDetail;   
        this.commonService.currentProductDetails= productDetail;    
        this.selectedImagePath = this.product.medias[0].file_url;
        this.selectedImageIndex = 0;
        this.paramCurrency=this.cartService.selectedCurrency;
        this.productDetailsIsDone=true;        
        this.quantityControl.setValue(1);
        this.isDoneEmit.emit(true);      
      });  

  }

  }  

  ngOnInit(): void {
    this.quantityControl.setValue(1);
    this.quantityControl.valueChanges.subscribe(value => {
      if ((Number.isInteger(value)) && (value>0)  && (value<1000)) {
        this.quantityIsValid=true;
      }  
      else {
        this.quantityIsValid=false;
      }  
    });
  }

  openInfoDialog() {            
    if (!this.screenService.infoDialogIsOpen) {              
      this.infoDialog.open(InfoDialogComponent, { data: {header: this.infoDialogHeader, content: this.infoDialogContent} });                         
    }  
  }

  incQuantity() {
    if (Number.isInteger(this.quantityControl.value)) {
      if (this.quantityControl.value<1) {
        this.quantityControl.setValue(1);
      }
      else {
        if (this.quantityControl.value<999) {
          this.quantityControl.setValue(this.quantityControl.value+1);
        } 
      }
    }  
    else {
      this.quantityControl.setValue(1);
    }  
  }

  decQuantity() {
    if (Number.isInteger(this.quantityControl.value)) {
      if (this.quantityControl.value>1) {
        if (this.quantityControl.value>999) {
          this.quantityControl.setValue(999);
        } 
        else {
          this.quantityControl.setValue(this.quantityControl.value-1);
        }  
      }      
    }       
  }

  addToCart(product:productType) {   
    if (!this.screenService.infoDialogIsOpen) {  
      if ((Number.isInteger(this.quantityControl.value)) && (this.quantityControl.value>0)  && (this.quantityControl.value<1000)) {
        //product.cartQuantity=this.quantityControl.value;
        this.cartService.addToCart(product,this.quantityControl.value);        
        this.openSnackBar(product.name+' termék a kosárba került!','Bevásárló kosár');    
      }  
      else {
        this.infoDialogHeader='Tisztelt látogató! ';         
        this.infoDialogContent='<p>A rendelhető mennyiség mező értéke 1000-nél kisebb pozitív egész szám lehet! </p';                
        //this.openInfoDialog();        
      }  
      this.screenService.infoDialogIsOpen=false;             
    }  
  } 

}