import { Component, OnInit, HostListener } from '@angular/core';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { currencyType } from '../models';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  
 
  searchText:string=''; 

  showCatMenu:boolean=false;

  selectedCurrency:currencyType=this.commonService.setDefaultCurrencyData();  

  notFixed!: boolean;    
  topPosToStartShowing = 100;  

  @HostListener('window:scroll') 
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;    
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.notFixed = true;
    } else {
      this.notFixed = false;      
    } 
    
  }

  constructor(public cartService: CartService, 
              public screenService: ScreenService,
              public commonService: CommonService,
              private router: Router,) { }

  getSearchText(searchText:string) {
    this.searchText=searchText; 
    if ( (searchText.length>=3) && (searchText.length<=100) ) {
      this.router.navigate(['/search',this.searchText]); 
    }  
  }

  catButtonMouseOver() {  
    if (this.screenService.popUpCatMenu) {
      this.showCatMenu=true; 
    }    
  }

  onChoiseCurrency(currency:currencyType) {
    this.cartService.selectedCurrency=currency;
    this.selectedCurrency=currency;  
    this.cartService.saveCurrencyToLocalStorage(currency);    
  }

  ngOnInit(): void {
    
  }

}
