import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { CartService } from '../cart.service';


@Component({
  selector: 'app-product-list-sales',
  templateUrl: './product-list-sales.component.html',
  styleUrls: ['./product-list-sales.component.css']
})
export class ProductListSalesComponent implements OnInit {
  
  productListDone!: boolean;

  constructor(public commonService: CommonService,
    public cartService: CartService, ) { }
  
  ngOnInit(): void {
   
  }

  getProductListIsDone(done: boolean) {
    this.productListDone=done;
  }

  pageIsDone() {
    return this.productListDone;
  }

}
