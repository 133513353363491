<div *ngIf="parentCategories.length>1" class="breadcrumb">     
  
    <div *ngFor="let category of parentCategories; let indexOfitem=index;" class="breadcrumb-container" >  
      <div class="breadcrumb-product-container"  [ngStyle]="{'backgroundColor': (category.id == this.categoryId) && (this.screenService.screenWidth>640) ? '#EFEFEF' : 'none' }"  [routerLink]="['/categories', category.id]">              
        <img class="option-img breadcrumb" aria-hidden [src]="category.medias[0].file_url" height="40px" width="40px">   
        <div class="cat-card-title breadcrumb"   [ngStyle]="{'color': category.id != this.categoryId ? '#666' : '#ff4081'}">
          {{category.name}}
        </div>             

        <div class="cat-card-breadcrumb-title" [routerLink]="['/categories', category.id]"  [ngStyle]="{'color': category.id != this.categoryId ? '#666' : '#ff4081' , 'backgroundColor': (category.id == this.categoryId) ? 'white' : 'none' }">
          <span *ngIf="indexOfitem>0" style="padding-left: 4px; padding-right: 2px;">|</span>
          {{category.name}}
        </div> 
      </div> 
    </div>        
  
</div>
