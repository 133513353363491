import { Component, HostListener } from '@angular/core';
import { ScreenService } from './screen.service';
import { CommonService } from './common.service';
import { CartService } from './cart.service';
import { categoryType, localStorageCart, currencyType } from './models';
import { Location } from '@angular/common';
import { Router,  NavigationEnd, Event as NavigationEvent} from '@angular/router';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  categories: categoryType[] = [];   
  categoriesFiltered: categoryType[] = []; 
  subCategoriesFiltered: categoryType[] = [];
  loadCatMenu=true;

  notFixed!: boolean;
  popUpProductDetailsTopMenu!: boolean;  
  scroll88px!: boolean
  showProductDetailsTopMenu:boolean = false;
  topPosToStartShowing = 100;  
  topPosToProductDetailsTopMenu = 700;   
  currentRoute:string='';
    
  selectedCurrency:currencyType=this.commonService.setDefaultCurrencyData();

  loadCartFromLocalStorageIsDone:boolean=false;

  @HostListener('window:scroll') 
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;    
    this.notFixed=(scrollPosition >= this.topPosToStartShowing);    
    this.scroll88px=(scrollPosition >= 88); 
    this.popUpProductDetailsTopMenu =(this.showProductDetailsTopMenu) && (scrollPosition >= this.topPosToProductDetailsTopMenu);    
  }

  constructor(public screenService: ScreenService,
              public commonService: CommonService,
              public cartService: CartService,
              private router: Router,
              private location: Location,) {   
                this.showProductDetailsTopMenu=false;
                this.router.events.subscribe( (event: NavigationEvent) => {                  
                   if(event instanceof NavigationEnd) {
                    this.currentRoute=location.path(); 
                    this.screenService.popUpCatMenu=location.path()!='';                         
                    this.showProductDetailsTopMenu=this.currentRoute.substring(0, 10)=='/products/';                   
                    this.cartService.cartComponentIsActive=this.currentRoute.substring(0, 5)=='/cart';                                    
                   }
                });

                
  }  
             
  onChoiseCurrency(currency:currencyType) {
    this.cartService.selectedCurrency=currency;
    this.selectedCurrency=currency;    
    this.cartService.saveCurrencyToLocalStorage(currency);
  }

  loadCartFromLocalStorage() {
    this.cartService.localStorageCart=JSON.parse(localStorage.getItem("cartItems") || '{}');
    if (this.cartService.localStorageCart.cartDate==undefined) {
      this.cartService.localStorageCart={cartDate: new Date(), cartItems:[]};              
    }    
    var now=new Date();          
    now.setHours(now.getHours()-24);
    var limitDate=Date.parse(formatDate(now,"yyyy-MM-dd hh:mm",'en-US'));
    var lastDate=Date.parse(formatDate(this.cartService.localStorageCart.cartDate,"yyyy-MM-dd hh:mm",'en-US'));
    if (limitDate<=lastDate) {
      this.cartService.items=this.cartService.localStorageCart.cartItems;  
    }
    this.loadCartFromLocalStorageIsDone=true;
  }  

  ngOnInit(): void {
    window.onresize = () => this.screenService.screenWidth = window.innerWidth;            

    this.commonService.getAllCategories().subscribe((categories: categoryType[]) => {
      this.categories = categories;            

      this.categoriesFiltered=this.categories.filter(function isMainCat (a) {        
        return a.parentId==0;
      });     

      this.subCategoriesFiltered=this.categories.filter(function isMainCat (a) {        
        return a.parentId>0;
      });      
   });

   this.loadCartFromLocalStorage();
   this.cartService.sumPrice=this.cartService.getSumValue();        

  }            
}
