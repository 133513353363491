import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../cart.service';


@Component({
  selector: 'app-product-search-result',
  templateUrl: './product-search-result.component.html',
  styleUrls: ['./product-search-result.component.css']
})
export class ProductSearchResultComponent implements OnInit {

  searchText: any;
  productListDone!: boolean;

  constructor(private route: ActivatedRoute,
              public cartService: CartService, ) {     
    this.route.paramMap.subscribe(params => {                  
      const routeParams = this.route.snapshot.paramMap;      
      this.searchText=routeParams.get('searchText'); 
    },error=> {  });
}

  ngOnInit(): void {
  }

  getProductListIsDone(done: boolean) {
    this.productListDone=done;
  }

  pageIsDone() {
    return this.productListDone;
  }

}
