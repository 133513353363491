import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CartService } from '../cart.service';
import { paymentType, shippingType, productType } from '../models';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { YesnoDialogComponent } from '../yesno-dialog/yesno-dialog.component';
import { formatNumber} from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  
 
  shippingCosts = this.cartService.commonService.getShippingPrices();
  payments = this.cartService.commonService.getPayments();
  
  isEditable = true;    
  productsStepGroup!: FormGroup; 
  shippingStepGroup!: FormGroup;
  paymentStepGroup!: FormGroup;
  customerStepGroup!: FormGroup;    
  

  infoDialogHeader: string ='';
  infoDialogContent: string ='';

  yesnoDialogHeader: string ='';
  yesnoDialogContent: string ='';

  
  
  constructor(
    public cartService: CartService,
    private formBuilder: FormBuilder, 
    private router: Router,
    public infoDialog: MatDialog,    
    public yesnoDialog: MatDialog,
    public commonService: CommonService ) {       

   }  

  quantityKeyUp(event: Event, productItem:productType) {
    var stringValue = (event.target as HTMLInputElement).value;  
    var value:number=Number(stringValue);  
    this.cartService.sumPrice=this.cartService.getSumValue();        
    this.cartService.saveCartToLocalStorage();    
  }

  openInfoDialog() {    
    this.infoDialog.open(InfoDialogComponent, { data: {header: this.infoDialogHeader, content: this.infoDialogContent} });
  }

  openYesnoDialog(product:productType) {    
    const dialogRef =this.yesnoDialog.open(YesnoDialogComponent, { data: {header: this.yesnoDialogHeader, content: this.yesnoDialogContent} });
        
    dialogRef.afterClosed().subscribe(result => {      
      if (result==true) {
        var index:number=-1;
        var i:number=-1;
        this.cartService.items.forEach(function(item){       
          i=i+1;  
          if (item.id==product.id) { index=i; }
        });         
     
        this.cartService.delItems(product);  
        this.cartService.sumPrice=this.cartService.getSumValue();                 
      }
    });
  }
 

  deleteFromCart(product:productType): void {
    this.yesnoDialogHeader='Bevásárló kosár';         
    this.yesnoDialogContent='Valóban törli a <br><span class="sumrow">'+product.name+'</span><br> terméket a kosárból?';
    this.openYesnoDialog(product);    
  }

  choiseShipping(shipping:shippingType): void {  
    this.cartService.shipping.id=shipping.id;
    this.cartService.shipping.type=shipping.type;
    this.cartService.shipping.price=shipping.price;   
   
  }

  choisePayment(payment:paymentType): void {  
    this.cartService.payment.id=payment.id;
    this.cartService.payment.type=payment.type;       
  }
  
  onSubmit(): void {      
       
    this.infoDialogHeader='Tisztelt '+this.customerStepGroup.value['name']+'! ';         
    this.infoDialogContent='<h4> Az ön megrendelését rögzítettük. </h4>'+                                                 
                           '<p class="dialog-content"> Szállítási cím: '+this.customerStepGroup.value['address']+
                           '<br> Szállítási mód: '+this.cartService.shipping.type+                                                      
                           '<br> Fizetési mód: '+this.cartService.payment.type+                           
                           '<br> Telefon: '+this.customerStepGroup.value['phone']+
                           '<br> E-mail: '+this.customerStepGroup.value['email']+
                           '</p><hr>'+
                           '<table>'+                            
                           '<tr><td >A megrendelés értéke:</td><td class="align-right">'+ formatNumber(this.cartService.sumPrice/this.cartService.selectedCurrency.switchValue, 'hu', '0.0-'+this.cartService.selectedCurrency.decimal) +' '+this.cartService.selectedCurrency.symbol+'.</td></tr>'+
                           '<tr><td >Szállítás:</td><td class="align-right">'+formatNumber(this.cartService.shipping.price/this.cartService.selectedCurrency.switchValue, 'hu', '0.0-'+this.cartService.selectedCurrency.decimal)+' '+this.cartService.selectedCurrency.symbol+'.</td></tr>'+
                           '<tr class="sumrow"><td>Átvételkor fizetendő:</td><td class="align-right">'+formatNumber((this.cartService.sumPrice+this.cartService.shipping.price)/this.cartService.selectedCurrency.switchValue, 'hu', '0.0-'+this.cartService.selectedCurrency.decimal)+' '+this.cartService.selectedCurrency.symbol+'.</td></tr>'+                           
                           '</table>'+
                           '<hr>';

    this.openInfoDialog();                       

    this.productsStepGroup.reset();
    this.shippingStepGroup.reset();
    this.paymentStepGroup.reset();
    this.customerStepGroup.reset()
    
    this.cartService.sumPrice =0;
    this.cartService.clearCart();

    this.router.navigate(['']);    
    
  }
 
  incQuantity(productItem:productType) {
    
      if (productItem.cartQuantity<1) {
        productItem.cartQuantity=1;
      }
      else {
        if (productItem.cartQuantity<999) {
          productItem.cartQuantity=productItem.cartQuantity+1;
        } 
      }

      this.cartService.sumPrice=this.cartService.getSumValue();       
      this.cartService.saveCartToLocalStorage();     
  }

  decQuantity(productItem:productType) {
   
      if (productItem.cartQuantity>1) {
        if (productItem.cartQuantity>999) {
          productItem.cartQuantity=999;
        } 
        else {
          productItem.cartQuantity=productItem.cartQuantity-1;
        }  
      }      
   
      this.cartService.sumPrice=this.cartService.getSumValue();       
      this.cartService.saveCartToLocalStorage();     
  }

  
  ngOnInit(): void {                   
    
    this.productsStepGroup = this.formBuilder.group({     
     // quantitysIsValid: [ this.cartService.cartIsValid(), Validators.required ]
    }); 

    this.shippingStepGroup = this.formBuilder.group({
      shippingGroup: ['',Validators.required]
    });    

    this.paymentStepGroup = this.formBuilder.group({
      paymentGroup: ['',Validators.required] 
    });  

    this.customerStepGroup = this.formBuilder.group({
      name: ['',Validators.required],
      address: ['',Validators.required],
      phone: ['',Validators.required],
      email: ['',Validators.required],      
    });   
  }

}





