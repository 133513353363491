<div>

  <div class="top-menu"> 
  
    <div (mouseleave)="this.showCatMenu=false;">
      <button (mouseover)="catButtonMouseOver()"  mat-button  class="toolbar-category-button">    
        <mat-icon style="vertical-align: middle; color: white !important; ">dashboard</mat-icon>
        KATEGÓRIÁK    
      </button>                
      <app-category-menu *ngIf="(this.screenService.popUpCatMenu)" [paramIsVisible]="(showCatMenu)" (mouseleave)="this.showCatMenu=false;" (click)="this.showCatMenu=false;"  class="toolbar-category-menu"  [ngStyle]="{'display': this.notFixed ? 'none' : 'block' }" ></app-category-menu>
    </div>

    <button mat-button routerLink="/sales" class="toolbar-menu-button">     
      <mat-icon style="vertical-align: middle; " color="warn">star_border</mat-icon>
      Akciók
    </button>  
  
    <button mat-button routerLink="/contact" class="toolbar-menu-button">
      <mat-icon style="vertical-align: middle; color: #82b2e2 !important; ">perm_phone_msg</mat-icon>
      Ügyfélszolgálat
    </button>    
    

  <!--
  <button mat-button routerLink="/shipping">Szállítási díjaink</button>
  <button mat-button routerLink="/payment">Fizetési módok</button>
  -->  

  </div>

  <div class="toolbar-currency-container">     
    
    <div style="width: 85px; ">
      <button mat-icon-button [matMenuTriggerFor]="menu" title="Valuta neme" style="font-size: 15px; font-weight: 300; width: 100%; text-align: right;">    
        <span style="vertical-align: middle; ">{{this.cartService.selectedCurrency.iso3Code}}</span>
        <mat-icon class="flag-icon-{{this.cartService.selectedCurrency.flag}}" style="vertical-align: middle; margin-left: 8px;"></mat-icon>    
        <mat-icon style="vertical-align: middle;">more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" >    
        <div style=" text-align: center; padding:0px;">Választható<br>valuták</div>
        <hr>
        <button mat-menu-item *ngFor="let currency of this.cartService.currencies" (click)="onChoiseCurrency(currency)">
          <mat-icon class="flag-icon-{{currency.flag}}"></mat-icon>
          {{currency.iso3Code}} - {{currency.symbol}}      
          <span *ngIf="!currency.default">
            = {{currency.switchValue}} {{this.cartService.currencies[0].symbol}}
          </span>
        </button>       
      </mat-menu>
    </div>

  </div>

</div>

<!--
<app-category-menu [paramIsVisible]="showCatMenu" (mouseleave)="this.showCatMenu=false;" (click)="this.showCatMenu=false;"  style=" max-width: 1560px; top: 128px; position: fixed; background-color: white; color: #222; font-weight: 400;  "  [ngStyle]="{'display': this.screenService.notFixed ? 'none' : 'block' }" ></app-category-menu>
-->  

  





