import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { shippingType } from '../models';
import { CommonService } from '../common.service';


@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})

export class ShippingComponent implements OnInit {

  loadTable=true;  
  displayedColumns: string[] = ['id', 'type', 'price'];
  shippingCosts = new MatTableDataSource<shippingType>();

  @ViewChild(MatPaginator)
  paginator!: MatPaginator; 
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(public commonService: CommonService) {

  }
  
  ngAfterViewInit() {
    this.shippingCosts.paginator = this.paginator;
    this.shippingCosts.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.shippingCosts.filter = filterValue.trim().toLowerCase();

    if (this.shippingCosts.paginator) {
      this.shippingCosts.paginator.firstPage();
    }
  }

  ngOnInit(): void {

    this.commonService.getShippingPrices().subscribe((shippings: shippingType[]) => {
      this.shippingCosts.data = shippings;
      this.loadTable=false;
    });



  }

}
