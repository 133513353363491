import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { currencyType } from '../models';

@Component({
  selector: 'app-top-top-menu',
  templateUrl: './top-top-menu.component.html',
  styleUrls: ['./top-top-menu.component.css']
})
export class TopTopMenuComponent implements OnInit {

 
 
  searchText:string=''; 

  selectedCurrency:currencyType=this.commonService.setDefaultCurrencyData();  

  showCartMenu:boolean=false; 
  

  constructor(public cartService: CartService, 
              public screenService: ScreenService,
              public commonService: CommonService,
              private router: Router,) { }

  getSearchText(searchText:string) {
    this.searchText=searchText; 
    if ( (searchText.length>=3) && (searchText.length<=100) ) {
      this.router.navigate(['/search',this.searchText]); 
    }  
  }

  onChoiseCurrency(currency:currencyType) {
    this.cartService.selectedCurrency=currency;
    this.selectedCurrency=currency;   
    this.cartService.saveCurrencyToLocalStorage(currency);   
  }

  getCartMenuIsClose(close: boolean) {
    if (close) {
      this.showCartMenu=false;
    }
  }

  ngOnInit(): void {
    
  }

}
