<div *ngIf="this.paramIsVisible" style="box-shadow: 1px 1px 6px rgba(0,0,0,.54); border-radius: 0px; background-color: rgba(0,0,0,.04);  width: 420px; " >

  <div *ngIf="this.cartService.items.length>0" class="popup-cart-head-item">
     NEMRÉG HOZZÁADOTT 
  </div>  

  <div *ngIf="this.cartService.items.length==0" class="popup-cart-head-item">
    AZ ÖN KASARA ÜRES! 
 </div>  

  <div class="popup-cart-item" *ngFor="let item of this.cartService.items; let indexOfitem=index;" [ngStyle]="{'color': item.cartQuantity>0 ? '#000' : '#f44336' }" >
    
    <div style="vertical-align: middle; display: inline-block; font-size: 13px; width: 15%; cursor: pointer;" *ngIf="item.medias.length>0"  >       
        <img class="option-img" src="{{ item.medias[0].file_url }}" [routerLink]="['/products', item.id]" (click)="itemClick()"  width="40px" height="40px" />  
    </div> 

    <div [routerLink]="['/products', item.id]" (click)="itemClick()" style="cursor: pointer; vertical-align: middle; display: inline-block; font-size: 11px; width: 45%; white-space: normal; line-height: 1;  ">           
      {{item.name}} 
    </div>    
    
    <div style="vertical-align: middle; display: inline-block; font-size: 11px; width: 10%; text-align: right;">           
       <span *ngIf="item.cartQuantity>0 ">
         {{item.cartQuantity}} db.
       </span> 
    </div>   
 
    <div style="vertical-align: middle; display: inline-block; font-size: 11px; width: 25%; text-align: right; font-weight: 500; color: #3f51b5; line-height: 1;">            
        
      <div *ngIf="(item.cartQuantity>0) && (item.discountPercent>0)" style="width: 100%;  font-size: 11px; color:red;text-decoration:line-through">
        <span style="color:black">               
          {{ ((item.origPrice*item.cartQuantity)) | mycurrency:this.cartService.selectedCurrency }}
        </span>
      </div>  
      <div *ngIf="item.cartQuantity>0 ">
        {{ ((item.price*item.cartQuantity)) | mycurrency:this.cartService.selectedCurrency }}
      </div>

    </div>

    <div style="vertical-align: middle; display: inline-block; font-size: 10px; width: 5%; text-align: right; font-weight: 500; ">            
      <button mat-icon-button (click)="deleteFromCart(item);" style="vertical-align: middle; transform: scale(0.7);" color="basic">
        <i color="basic" class="material-icons">delete</i>
      </button>  
    </div>  

</div>    


<div *ngIf="this.cartService.items.length>0" class="popup-cart-sum-item" >
  <div style="vertical-align: middle; display: inline-block;  width: 60%;   ">              
    VÉGÖSSZEG: <span style="font-weight: 300;">{{this.cartService.items.length}} TERMÉK</span>
  </div>
  <div style="vertical-align: middle; display: inline-block;  width: 35%; text-align: right;  ">              
    {{ (this.cartService.sumPrice) | mycurrency:this.cartService.selectedCurrency }}
  </div>
</div>  

<div *ngIf="this.cartService.items.length>0" class="popup-cart-head-item">
    <button mat-flat-button color="primary" routerLink="/cart" style="margin-top: 8px; margin-bottom: 4px; width: 100%; text-align: center;" >
      
     
      <span style="font-weight: 400;">Kosár tartalma</span>
      <i class="material-icons">arrow_right</i>

    </button> 
</div> 

</div>
