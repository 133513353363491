import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  screenWidth:number=window.innerWidth;  
 

  colCount=4;
  colDetailCount=9;
  colDetailDescCount=6;
  colDetailImgCount=3;
  detailRowHeight=400;  
  infoDialogIsOpen:boolean=false;

  popUpCatMenu:boolean=true; 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)  
  .pipe(
    map(result => result.matches),
    shareReplay()
  ); 

  isTabletset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
  .pipe(
      map(result => result.matches),
      shareReplay()
    ); 

  constructor(private breakpointObserver: BreakpointObserver) { } 

  getColCount ()  {
    /*if (this.screenWidth>1920) { this.colCount=8; }*/
    if (this.screenWidth>2200) { this.colCount=9; }
    if (this.screenWidth<=2200) { this.colCount=8; }
    if (this.screenWidth<=1920) { this.colCount=7; }
    if (this.screenWidth<=1600) { this.colCount=6; }     
    if (this.screenWidth<=1400) { this.colCount=5; }
    if (this.screenWidth<=1000) { this.colCount=4; }
    if (this.screenWidth<=900) { this.colCount=3; }
    if (this.screenWidth<=700) { this.colCount=2; }
    if (this.screenWidth<=450) { this.colCount=1; }
    return this.colCount;
  }

  getDetailColCount ()  {
    this.colDetailCount=9;
    if (this.screenWidth<840) { this.colDetailCount=1; }    
    return this.colDetailCount;
  }

  getDetailDescColCount ()  {
    this.colDetailDescCount=6;
    if (this.screenWidth<840) { this.colDetailDescCount=1; }    
    return this.colDetailDescCount;
  }

  getDetailImgColCount ()  {
    this.colDetailImgCount=3;
    if (this.screenWidth<840) { this.colDetailImgCount=0; }    
    return this.colDetailImgCount;
  }

  getDetailRowHeight () {
    this.detailRowHeight=400;
    if (this.screenWidth<840) { this.detailRowHeight=700; }    
    if (this.screenWidth<575) { this.detailRowHeight=770; }      
    return this.detailRowHeight;
  }
  
}
