import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CartService } from '../cart.service';
import { productType } from '../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-menu',
  templateUrl: './cart-menu.component.html',
  styleUrls: ['./cart-menu.component.css']
})
export class CartMenuComponent implements OnInit {

  @Input() paramIsVisible!: boolean; 
  @Output() isCloseEmit = new EventEmitter<boolean>();

  constructor(public cartService: CartService,
              private router: Router,) {  }

  deleteFromCart(product:productType): void {
    this.cartService.delItems(product);  
    this.cartService.sumPrice=this.cartService.getSumValue();      
  }

  itemClick() {
    this.isCloseEmit.emit(true);  
  }

  ngOnInit(): void {
     
  }

}
