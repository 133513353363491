import { Injectable } from '@angular/core';
import { paymentType, shippingType, productType, currencyType, localStorageCart } from './models';
import { CommonService } from './common.service';



@Injectable({
  providedIn: 'root'
})


export class CartService {
  items:productType[] = [];     
  currencies:currencyType[] = [];
  selectedCurrency:currencyType=this.commonService.setDefaultCurrencyData();  
  shipping: shippingType={id:0, type:'', price:0};
  payment: paymentType={id:0, type:''};
  sumPrice: number=0;     

  loadCurrencyFromLocalStorageIsDone:boolean=false;

  localStorageCart!:localStorageCart;

  cartComponentIsActive:boolean=false;
    
  constructor(           
    public commonService: CommonService   
  ) {
    this.commonService.getCurrencies().subscribe((currencies: currencyType[]) => {            
      this.currencies = currencies;         
      this.loadCurrencyFromLocalStorage(); 
    });  
   }

  addToCart(product:productType, q:number) {          

    var index:number=-1;
    var i:number=-1;
    this.items.forEach(function(item){       
      i=i+1;  
      if (item.id==product.id) { index=i; }
    });   
    

    if (index==-1) {
      product.cartQuantity=q;
      this.items.push(product);        
      this.sumPrice=this.sumPrice+(product.price*q); 
    }  
    else {  
      var pq:number=this.items[index].cartQuantity;
      var tq:number=pq+q;  
      this.sumPrice=this.sumPrice+(product.price*q);       
      this.items[index].cartQuantity=tq;                  
    }           
        
    this.saveCartToLocalStorage();    
  }

  delItems(product:productType) {
    this.sumPrice=this.sumPrice-(product.price*product.cartQuantity);
    const index: number =this.items.indexOf(product);    
    this.items.splice(index,1);

    this.saveCartToLocalStorage()
    
  }

  getItems() {
    return this.items;
  }

  getCartSumValue() {    
    return this.sumPrice;    
  }

  getSumValue() {    
    var sum:number=0;
    this.items.forEach(function(item){             
      sum=sum+(item.cartQuantity*item.price)
    })
    return sum;
  }

  cartIsValid() {    
    var val:boolean=true;    
    this.items.forEach(function(item){             
      if ((item.cartQuantity<1) || (item.cartQuantity>999)) {
         val=false;
      }
    })
    return val;
  }

  clearCart() {
    this.sumPrice=0;    
    this.items = [];    
    this.shipping.id=0;
    this.shipping.type='';
    this.shipping.price=0;
    this.payment.id=0;
    this.payment.type='';
    
    this.saveCartToLocalStorage();    
    return this.items;
  }

  saveCartToLocalStorage() {
    this.localStorageCart.cartDate=new Date();
    this.localStorageCart.cartItems=this.items;
    localStorage.setItem("cartItems", JSON.stringify(this.localStorageCart)); 
  }

  saveCurrencyToLocalStorage(currency:currencyType) {
    localStorage.setItem("selectedCurrency", JSON.stringify(currency));  
  }

  loadCurrencyFromLocalStorage() {
    var c:currencyType=JSON.parse(localStorage.getItem("selectedCurrency") || '{}');          
    if (c.id>0) {          
      this.selectedCurrency=c;                        
    }     
    else { 
      this.selectedCurrency=this.currencies[0];  
    }   
    this.loadCurrencyFromLocalStorageIsDone=true;
  }

}
