import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { categoryType, productType, manufacturerType, mediaType, taxType, customFieldGroupType } from './models';


@Injectable({
  providedIn: 'root'
})
export class CommonService { 

  title='Notebook';  
  title1='bolt';  

  httpPhpUrl='https://notebookbolt.raktarkeszletem.hu/php/';  

  currentProductDetails:productType=this.setEmptyProductData();  

  constructor(private http: HttpClient,) { }
    
  setEmptyCategoryData() {    
    return {id:0, parentId:0, name:'', title: '',  description: '', childCount:0, medias:[]};
  }

  setEmptyManufacturerData() {
    return {id:0, name: '', title: '', description:'', mfEmail:'', mfUrl:'', medias:[] };    
  }
 
  setEmptyCurrencyData() {
    return {id: 0, name: '', symbol: '', iso3Code: '', locale: '', flag: '', switchValue: 0, decimal: 0, default: false};        
  }

  setDefaultCurrencyData() {
    return {id: 1, name: 'Forint', symbol: 'Ft', iso3Code: 'HUF', locale: 'hu', flag: 'hu', switchValue: 1, decimal: 0, default: true};        
  }

  setEmptyTaxData() {
    return {id: 0, groupName: '', title: '', mathMethod: '', value: 0, nav_code: '', caseDescription:'', default: false };    
  }

  setCustomFieldData() {
    return {id: 0, name: '', value: '' };    
  }

  setCustomFieldGroupData() {
    return {id: 0, name: '', icon:'', customFields:[]};    
  }

  setEmptyProductData() {
    return {id:0, name:'', sku:'', price:0, origPrice:0, discountPercent:0, discountStartDate:new Date() , discountEndDate:new Date(), description: '', inStock:0, stockInfo:'', category:this.setEmptyCategoryData(), manufacturer:this.setEmptyManufacturerData(), medias:[], tax:this.setEmptyTaxData(), customFieldGroups:[], cartQuantity:1 };    
  }

  getShippingPrices() {
    return this.http.get<{id: number, type: string, price: number}[]>(this.httpPhpUrl+'getShippings.php');
  }
 
  getPayments() {
    return this.http.get<{id: number, type: string}[]>(this.httpPhpUrl+'getPayments.php');
  }

  getCategoryDetails(categoryId:number) {
    return this.http.get<{id: number, parentId: number, name: string; title: string; description: string; childCount: number; medias: mediaType[] }>(this.httpPhpUrl+'getCategoryDetails.php?id='+categoryId);
  }     

  getCategories(categoryId:number) {
    return this.http.get<{id: number, parentId: number, name: string; title: string; description: string; childCount: number; medias: mediaType[]}[]>(this.httpPhpUrl+'getCategories.php?id='+categoryId);
  }

  getAllCategories() {
    return this.http.get<{id: number,  parentId: number, name: string; title: string; description: string; childCount: number; medias: mediaType[]}[]>(this.httpPhpUrl+'getAllCategories.php');
  }

  getCategoryBreadcrumbItems(categoryId:number) {
    return this.http.get<{id: number, parentId: number, name: string; title: string; description: string; childCount: number; medias: mediaType[]}[]>(this.httpPhpUrl+'getBreadcrumbItems.php?id='+categoryId);
  }

  getProductDetails(productId: number) {    
    return this.http.get<{ id: number, name: string, sku: string, price: number, origPrice: number, discountPercent: number, discountStartDate: Date, discountEndDate: Date, description: string, inStock: number, stockInfo: string, category:categoryType, manufacturer:manufacturerType, medias:mediaType[], tax: taxType, customFieldGroups:customFieldGroupType[], cartQuantity:number}>(this.httpPhpUrl+'getProductDetails.php?id='+productId);
  }

  getProducts(categoryId:number,searchText:string) {    
    if (searchText!='') {
      /*const headers = { 'content-type': 'application/json'}; 
      const params = new HttpParams() new formdata 
        .append('searchText', searchText);  */
      return this.http.get<{ id: number, name: string, sku: string, price: number, origPrice: number, discountPercent: number, discountStartDate: Date, discountEndDate: Date, description: string, inStock: number, stockInfo: string, category:categoryType, manufacturer:manufacturerType, medias:mediaType[], tax: taxType, customFieldGroups: customFieldGroupType[], cartQuantity:number}[]>(this.httpPhpUrl+'getProducts.php?searchText='+searchText);
    }
    else {
      return this.http.get<{ id: number, name: string, sku: string, price: number, origPrice: number, discountPercent: number, discountStartDate: Date, discountEndDate: Date, description: string, inStock: number, stockInfo: string, category:categoryType, manufacturer:manufacturerType, medias:mediaType[], tax: taxType, customFieldGroups: customFieldGroupType[], cartQuantity:number}[]>(this.httpPhpUrl+'getProducts.php?cat_id='+categoryId);    
    }  
  }

  getCategoryManufacturers(categoryId:number) {  
    return this.http.get<{id: number, name: string; title: string; description: string; mfEmail: string; mfUrl: string; medias: mediaType[]}[]>(this.httpPhpUrl+'getCategoryManufacturers.php?cat_id='+categoryId);
  }

  getCurrencies() {  
    return this.http.get<{id: number, name: string, symbol: string, iso3Code: string, locale: string, flag: string, switchValue: number, decimal: number, default: boolean}[]>(this.httpPhpUrl+'getCurrenciesList.php');
  }

  getSortByTypes() {  
    return this.http.get<{id: number, name: string;}[]>(this.httpPhpUrl+'getSortByTypes.php');
  }

  getInStockTypes() {  
    return this.http.get<{id: number, name: string;}[]>(this.httpPhpUrl+'getInStockTypes.php');
  }
  
}
