import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../common.service';
import { ScreenService } from '../screen.service';
import { categoryType } from '../models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit, OnChanges {  
  categories: categoryType[] = [];              
  categoryDetail:categoryType=this.commonService.setEmptyCategoryData();
 
  @Input() paramCategoryId!: number; 
  @Output() isDoneEmit = new EventEmitter<boolean>();  

  constructor(public commonService: CommonService,
              public screenService: ScreenService,
              private route: ActivatedRoute,
             ) {  } 

  ngOnChanges() {     
    this.categories = [];              
    this.categoryDetail=this.commonService.setEmptyCategoryData();     
    this.isDoneEmit.emit(false);
    this.commonService.getCategoryDetails(this.paramCategoryId).subscribe((resultCategoryDetail: categoryType) => {
       this.commonService.getCategories(resultCategoryDetail.id).subscribe((resultCategories: categoryType[]) => {         
         this.categoryDetail = resultCategoryDetail;                           
         this.categories = resultCategories;                        
         this.isDoneEmit.emit(true);  
       },error=> { });
    });
  }

  ngOnInit(): void {  }

}
