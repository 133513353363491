import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
 
  productListDone!: boolean;
  categoryListDone!: boolean;
  

  
  constructor(public commonService: CommonService,
              public cartService: CartService,
              public screenService: ScreenService,) {  }  

  getProductListIsDone(done: boolean) {
    this.productListDone=done;
  }

  getCategoryListIsDone(done: boolean) {
    this.categoryListDone=done;
  }
 

  pageIsDone() {
    return this.productListDone && this.categoryListDone;
  }

  ngOnInit(): void { 

    

  }

}
