

<div class="page-caption" *ngIf="(this.categoryDetail.id!=0) && (this.categoryDetail.childCount>0)" >
  <h1 style="margin: 0px !important;  padding-bottom: 15px;">
    <div mat-card-avatar class="category-header-image" style="vertical-align: bottom; display: inline-block; background-image: url('{{categoryDetail.medias[0].file_url}}');" ></div> 
    {{categoryDetail.name}}
  </h1> 
</div> 


<div class="page-caption" *ngIf="(this.categoryDetail.id==0) && (this.products.length>0) && (this.paramSearchText=='')" >
  
  <h2 style="color: #3f51b5; font-weight: 400; font-size: 26px; letter-spacing: -1px; ">
    <mat-icon color="warn" style="vertical-align: middle; transform: scale(1.2);">stars</mat-icon>
        Akciós termékeink
  </h2>  
  
</div>


<div *ngIf="(this.paramSearchText!='') && (this.isDone)"  style="padding: 20px 10px 0px 10px; margin-bottom: 0px;">
  <div class="search-result-count">
    <mat-icon color="primary" class="search-result-count-icon">search</mat-icon>
    &nbsp;Keresett kifejezés:  
    <span style="font-weight: 400;"> "{{this.paramSearchText}}", </span>
    
    <span style="white-space: nowrap;">{{this.products.length}} db találat.</span>  
  </div> 
</div>


<div *ngIf="(this.products.length>0)"   style=" margin-bottom: 5px; "> 
  

  <mat-form-field  appearance="fill" class="mat-form-field-sortby">
    <mat-label> 
      <mat-icon style="vertical-align: text-top; ">sort</mat-icon>
      Rendezési kritérium
    </mat-label>
    <mat-select [(ngModel)]="this.sortBySelectedValue"  (ngModelChange)="this.onSortBySelection()">    
      <mat-option *ngFor="let sortBy of sortByTypes" [value]="sortBy.id">{{sortBy.name}}</mat-option> 
    </mat-select>
  </mat-form-field>  

  <div  class="filter-button" (click)="this.showFilter=!this.showFilter;" >    
    <i class="material-icons" style="top: 7px; position: relative; transform: scale(0.5);">filter_list</i>
    <span style="padding-left: 0px;">SZŰRŐK</span>
    <i *ngIf="!this.showFilter" class="material-icons" style="padding-left: 6px; float: right; margin-top: 6px;">add_circle_outline</i>
    <i *ngIf="this.showFilter" class="material-icons" style="padding-left: 6px; float: right; margin-top: 6px;">remove_circle_outline</i>
  </div>

   

  <div class="filter-container" [ngStyle]="{'display': (this.screenService.screenWidth<=967) && (!this.showFilter) ? 'none' : 'inline-block' }">
  
    <mat-form-field appearance="fill" class="mat-form-field-filter left">
      <mat-label>
        <mat-icon style="vertical-align: text-top; ">assessment</mat-icon>
         Szűrés készletre
      </mat-label>
      <mat-select [(ngModel)]="this.inStockSelectedValue"  (ngModelChange)="this.onProductsFilter()">      
        <mat-option *ngFor="let inStockType of inStockTypes" [value]="inStockType.id">{{inStockType.name}}</mat-option> 
      </mat-select>
    </mat-form-field>   
    
    <mat-form-field appearance="fill" class="mat-form-field-filter right">
      <mat-label>
        <mat-icon style="vertical-align: text-top; ">developer_board</mat-icon>
        Szűrés gyártókra
      </mat-label>
      <mat-select [(ngModel)]="this.manufacturedBySelectedValue" (ngModelChange)="this.onProductsFilter()" multiple >     
        <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.id">{{manufacturer.name}}</mat-option> 
      </mat-select>
    </mat-form-field>    

    
  
  
  
  <div class="mat-form-field-price-filter" >
    <ngx-slider (userChangeEnd)="this.onProductsFilter()"  [(value)]="priceMinValue" [(highValue)]="priceMaxValue" [options]="this.priceSliderOptions" ></ngx-slider>
  </div>  
  
</div>

  
  
</div> 



<mat-grid-list cols="{{screenService.getColCount()}}"  rowHeight="500px">  
  <mat-grid-tile *ngFor="let product of productsFiltered" >
    <div class="product-desc-container"  >     
      <mat-card class="my-card">
        
        <mat-card-header style="margin-bottom: 0px !important; display: block;"> 
          <mat-card-title style="height: 20px !important; margin-bottom: 5px !important;" > 
            <a [title]="product.name + ' részletek'" [routerLink]="['/products', product.id]">
              {{ product.name }}               
            </a> 
          </mat-card-title>          
          <mat-card-title style="height: 20px !important; font-size: 14px;" >             
            <a [title]="product.name + ' részletek'" [routerLink]="['/products', product.id]">              
              <span style="color: rgba(0,0,0,.87);">{{ product.sku }}</span>
            </a> 
          </mat-card-title>
          <mat-card-subtitle style=" text-align: right; height: 24px; line-height: 0.6; margin-bottom: 8px !important;" [ngStyle]="{'color': (product.inStock>5)  ? '#9ccc65' :(product.inStock<=0)  ? 'gray' : '#f44336'}">
            <img class="manufacturer-img productcard" *ngIf="product.manufacturer.medias.length>0"  src="{{ product.manufacturer.medias[0].file_url }}" [routerLink]="['/products', product.id]" />   
            <mat-icon style="vertical-align: middle; ">assessment </mat-icon>
            <span>{{product.stockInfo}} </span>
          </mat-card-subtitle> 
        </mat-card-header>
        
        <div class="product-img-container" *ngIf=" product.medias.length>0 "  >       
          <img class="product-img" src="{{ product.medias[0].file_url }}" [routerLink]="['/products', product.id]" />                      
        </div>
                
        <mat-card-content style="margin-bottom: 0px !important;">
          <p *ngIf="product.description" style="margin-bottom: 6px !important;">
            {{ product.description }}            
          </p>        
          <p *ngIf="!product.description">
              Nincs elérhető leírás!
          </p>            
        </mat-card-content>

        <mat-card-actions style="margin-top: 0px !important; padding-top: 0px !important;">               
          <div *ngIf="product.discountPercent>0" style="margin-bottom: 0px; padding-bottom: 0px; line-height: 1; color:red;text-decoration:line-through">
            <span style="color:black">{{product.origPrice | mycurrency:this.paramCurrency }}</span>
          </div>                   
          <div *ngIf="product.discountPercent==0" style="margin-bottom: 0px; padding-bottom: 0px; line-height: 1;">&nbsp;</div>                   
          <h2 style="margin-top: 0px; padding-top: 0px; line-height: 1;">            
            {{ (product.price) | mycurrency:this.paramCurrency }}            

          </h2>                      

          <button mat-mini-fab color="warn" *ngIf="product.discountPercent>0" [routerLink]="['/products', product.id]" style="width: 46px; height: 46px;font-size:13px; letter-spacing: 0px; top: 100px; right: 14px; position: absolute;">
            -{{product.discountPercent}}%
          </button>

          <button mat-flat-button class="two-color-button" (click)="addToCart(product)" >
            <i class="material-icons" style="padding-right: 6px;">shopping_cart</i>
            <span style="padding-left: 16px;">Kosárba tesz</span>
          </button>
        </mat-card-actions>
      
      </mat-card>
    </div>    
  </mat-grid-tile>
</mat-grid-list>
 


  
 