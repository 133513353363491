import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { infoDialogData } from '../models';


@Component({
  selector: 'app-yesno-dialog',
  templateUrl: './yesno-dialog.component.html',
  styleUrls: ['./yesno-dialog.component.css']
})
export class YesnoDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: infoDialogData ) { }

  ngOnInit(): void {
  }

}
