<div *ngIf="paramIsVisible">
  <img *ngIf="paramProduct.medias.length>0" class="product-img mini"  style="vertical-align: middle; padding: 4px; display: inline-block; margin-left: 30px;" src="{{ this.paramProduct.medias[0].file_url }}"   />                                
  <mat-label class="product-details-top-name">{{ this.paramProduct.name }}</mat-label>

  <div [ngClass]="{'product-details-right-container': this.paramProduct.discountPercent>0, 'product-details-right-container-without-percent': this.paramProduct.discountPercent==0 }">
      <div *ngIf="this.paramProduct.discountPercent>0" class="product-details-top-percent" >
        -{{this.paramProduct.discountPercent}}%
      </div>                         
      <div class="product-details-top-price-container" >            
        
        <div *ngIf="this.paramProduct.discountPercent>0" class="product-details-top-origprice" >Eredeti ár: 
          <span style="margin-bottom: 0px; padding-bottom: 0px; line-height: 1; color:red;text-decoration:line-through">
            <span style="color:#222;">
              {{ (this.paramProduct.origPrice) | mycurrency:this.paramCurrency }}
            </span>
          </span>
        </div>                               

       
        <div [ngStyle]="{'margin-top': this.paramProduct.discountPercent>0 ? '0px' : '12px' }" class="product-details-top-price">            
          {{ (this.paramProduct.price) | mycurrency:this.paramCurrency }}
        </div>                                                        
        
        <div *ngIf="this.paramProduct.discountPercent>0" class="product-details-top-savingprice">Megtakarítás: {{((this.paramProduct.origPrice-this.paramProduct.price)) | mycurrency:this.paramCurrency }}</div>                                      
        
      </div>  

      <button class="two-color-button" [ngStyle]="{'vertical-align': this.paramProduct.discountPercent>0 ? 'middle' : 'text-top' }" mat-flat-button color="primary" (click)="addToCart(paramProduct)" style="display: inline-block; ">
        <i class="material-icons" style="padding-right: 6px;">shopping_cart</i>
        <span style="padding-left: 16px;">Kosárba tesz</span>
      </button> 
      
  </div>

</div>
  
