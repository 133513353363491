import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { infoDialogData } from '../models';
import { ScreenService } from '../screen.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<InfoDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: infoDialogData,
               public screenService: ScreenService, )  {
                                           
  }

 

  ngOnInit(): void {
    
  }

  

}
