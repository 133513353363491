import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-category-product-list',
  templateUrl: './category-product-list.component.html',
  styleUrls: ['./category-product-list.component.css']
})
export class CategoryProductListComponent implements OnInit {

  categoryIdFromRoute!:number;
  productListDone!: boolean;
  categoryListDone!: boolean;    

  constructor(private route: ActivatedRoute,
              public commonService: CommonService,
              public cartService: CartService,) {     
    this.route.paramMap.subscribe(params => {                  
      const routeParams = this.route.snapshot.paramMap;
      this.categoryIdFromRoute = Number(routeParams.get('categoryId')); 
    },error=> {  });
  }

  getProductListIsDone(done: boolean) {
    this.productListDone=done;
  }

  getCategoryListIsDone(done: boolean) {
    this.categoryListDone=done;
  }

  pageIsDone() {
    return this.categoryListDone && this.productListDone;
  }

  ngOnInit(): void {
    
  }

}
