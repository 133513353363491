import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyPositiveNumbers]'
})
export class OnlyPositiveNumbersDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    //console.log(event.keyCode);
    if ([8, 9, 13, 27, 46, 37, 39, 38, 40].indexOf(event.keyCode) !== -1 ||
      (event.keyCode >= 47 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105)) {
        if ((event.keyCode === 96 || event.keyCode === 47 )  && (this.el.nativeElement.value + event.key).substr(0,1) === '0'  )        
           
          {          
          event.preventDefault();
          return false;

        } else {  
            return true;         
        }
    } else {
      event.preventDefault();
      return false;
    }
  }

  constructor(private el: ElementRef) {
   
   }

}
