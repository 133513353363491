<div *ngIf="this.paramIsVisible" (mouseleave)="selectedCategoryId=0;" [ngStyle]="{'box-shadow': this.screenService.popUpCatMenu ? '1px 1px 6px rgba(0,0,0,.54)' : ''}" style="box-shadow: 1px 1px 6px rgba(0,0,0,.54); height: 442px;  ">

  <!--
  <button *ngIf="this.screenService.popUpCatMenu"   mat-button  style="display: block; padding: 0px !important; margin: 0px !important; font-size: 21px; font-weight: 400; background-color: #000; border-radius: 0px; line-height: 64px;  width: 240px; top: 64px; position: fixed; color: white;">    
    <mat-icon style="vertical-align: middle; color: white !important; ">dashboard</mat-icon>
    KATEGÓRIÁK    
  </button>  
  -->

  <div class="category-menu-container">    
    <div *ngFor="let category of categoriesFiltered" class="category-menu-row" mat-list-item (mouseover)="setSubCategory(category)"  [routerLink]="['/categories', category.id]" >      
      <img class="option-img" style="margin-left: 30px" aria-hidden [src]="category.medias[0].file_url" height="40px" width="40px">   
      <div class="category-menu-label" >{{category.name}}</div> 
    </div>            
  </div>  

  <!--<div class="slider-container" [ngStyle]="{'background-image': (!this.screenService.popUpCatMenu) && (this.screenService.screenWidth>1017) ? 'url(https://notebookbolt.raktarkeszletem.hu/images/slider/slider8.jpg)' : ''}">  -->
  <div class="slider-container" [ngStyle]="{'background-image': (this.screenService.popUpCatMenu) ? '' : (this.screenService.screenWidth>1017) ? 'url(https://notebookbolt.raktarkeszletem.hu/images/slider/slider8.jpg)' : (this.screenService.screenWidth>817) ? 'url(https://notebookbolt.raktarkeszletem.hu/images/slider/slider9.jpg)' : 'url(https://notebookbolt.raktarkeszletem.hu/images/slider/slider12.jpg)' }">         
  
    <div (mouseleave)="selectedCategoryId=0;" [ngStyle]="{'width': ((this.subCategoriesFiltered.length>0) && (selectedCategoryId!=0)) ? '380px' : '0px'}"   style="overflow: hidden;  transition-duration: 0.5s; background-color: rgba(255,255,255,.88); height: 100%;  margin-bottom: 5px; padding-bottom: 20px; padding-top: 5px; display: inline-block;" > 
      <div *ngFor="let category of subCategoriesFiltered" class="category-submenu-row" mat-list-item  [routerLink]="['/categories', category.id]"  >
      
        <div *ngIf="selectedCategoryId!=0" style="width: 380px;">         

          <div style="display: inline; ">
             <img class="option-img" style="margin-left: 30px" aria-hidden [src]="category.medias[0].file_url" height="50px" width="50px">      
          </div>

          <div  class="category-submenu-label" >
            {{category.name}}
          </div> 

          <div *ngFor="let subCategory of categories" mat-list-item  [routerLink]="['/categories', subCategory.id]" style=" font-weight: 300; margin-left: 104px; cursor: pointer; line-height: 20px !important;" >  
            <div *ngIf="subCategory.parentId==category.id" class="category-subsubmenu-label">{{subCategory.name}}</div>
          </div>
     
        </div>  
      </div>            
    </div>     
  </div>
</div>
    
