import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../cart.service';
import { ScreenService } from '../screen.service';
import { productType, mediaType, currencyType } from '../models';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-details-top-menu',
  templateUrl: './product-details-top-menu.component.html',
  styleUrls: ['./product-details-top-menu.component.css']
})
export class ProductDetailsTopMenuComponent implements OnInit {

  @Input() paramProduct:productType=this.commonService.setEmptyProductData();
  @Input() paramIsVisible!: boolean; 
  @Input() paramCurrency: currencyType=this.commonService.setEmptyCurrencyData(); 

  constructor(
    private cartService: CartService,
    public screenService: ScreenService,
    public commonService: CommonService,
    public _snackBar: MatSnackBar,
    private router: Router, ) { }

  ngOnInit(): void {
      
  }

  openSnackBar(message: string, action: string) {
    const snackBarRef=this._snackBar.open(message, action, {duration: 3000,});
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['cart']);
     });
  }

  addToCart(product:productType) {      
    this.cartService.addToCart(product,1);        
    this.openSnackBar(product.name+' termék a kosárba került!','Bevásárló kosár');    
  }

}
