
  
  <mat-horizontal-stepper *ngIf="this.cartService.items.length>0"  linear #stepper >
    
    <mat-step  [editable]="(isEditable)" [completed]="this.cartService.cartIsValid()" >   
       
                  
          <ng-template matStepLabel>
            <span class="stepper-header">Kosár tartalma</span>
            <mat-icon class="stepper-header-icon">shopping_cart</mat-icon>
          </ng-template>          
           

          <mat-card style="margin-top: 20px;margin-bottom: 20px;">            
            

            <div class="cart-item" *ngFor="let item of this.cartService.items; let indexOfitem=index;">           
              <div class="cart-product-img-container" *ngIf="item.medias.length>0"  >       
                <img class="product-img mini" src="{{ item.medias[0].file_url }}" [routerLink]="['/products', item.id]" />  
              </div>
              <div class="cart-product-name-container"><div style="line-height: 1;"><a routerLink="/products/{{item.id}}" [ngStyle]="{'color': (item.cartQuantity<1) || (item.cartQuantity>999) ? 'red' : '' }" >{{ item.name }}</a></div></div>
              
              <div class="cart-product-price-container" [ngStyle]="{'color': (item.cartQuantity<1) || (item.cartQuantity>999) ? 'red' : '' }">
                
                <div style="margin-top: -13px; width: 100%;">                                
                  
                  
                  <div style="text-align: right; display: inline-block; vertical-align: middle;  width: 85%;  " >              

                    <div *ngIf="item.discountPercent>0" style="line-height: 1; color: #444; font-weight: 400; font-size: 13px;" >
                      <span style="margin-bottom: 0px; padding-bottom: 0px; line-height: 1; color:red;text-decoration:line-through">
                        <span style="color:#222;">
                          {{ (item.origPrice) | mycurrency:this.cartService.selectedCurrency }}
                        </span>
                      </span>
                    </div>                             
            
                   
                    

                    <div [ngStyle]="{'margin-top': item.discountPercent>0 ? '0px' : '6px' }" style="width: 100%;">
                      <div style="color: #999; font-size: 11px; letter-spacing: -0.4px; padding-top: 0px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 4px;padding-left: 1px; line-height: 1;" *ngIf="item.discountPercent==0" >Egységár:</div>                                      
                      <div  style=" margin-top: 0px; margin-bottom: 0px; line-height: 1; font-weight: 400; font-size: 15px; color:#000; width: 100%;">            
                        {{ (item.price) | mycurrency:this.cartService.selectedCurrency }}
                      </div>                                                        
                    </div>
                    
                                      

                  </div>  

                  <div class="cart-product-percent">
                    <div *ngIf="item.discountPercent>0" style="background-color: #f44336; color: white; vertical-align: middle; display: inline-block; margin-right: 15px; margin-left: 15px; width: 40px; height: 40px; line-height: 43px; text-align: center; font-size: 11px; border-radius: 90px; box-shadow: 1px 1px 6px rgba(0,0,0,.54); " >
                      -{{item.discountPercent}}%
                    </div>                         
                  </div> 
                  
              </div>

              </div>

              <div class="cart-product-quantity-container" >
                <button (click)="decQuantity(item)"  class="cart-product-quantity-dec" >
                  -
                </button>
                <mat-form-field appearance="outline" style="width: 120px; margin-top: 0px; margin-left: 6px; margin-right: 6px; ">
                  <mat-label style="text-transform: none; color: #3f51b5;">Mennyiség</mat-label>                  
                  
                  <input
                    matInput  
                    appOnlyPositiveNumbers     
                    [(ngModel)]="item.cartQuantity"  
                    (keyup)="quantityKeyUp($event,item)"    
                    type="number"   
                    placeholder=" "                                                                                        
                    min="1"                
                    max="999"     
                    pattern="d+"
                    step="1"    
                    style="font-weight: 600; text-align: center; font-size: 15px; top: 10px !important; position: absolute;"
                    required
                  >                   
                  
                  <span matSuffix style="color: #3f51b5; font-size: 15px; line-height: 1; height: 10px; top: -9px !important; position: relative;">&nbsp;db</span>
                </mat-form-field>     
                <button (click)="incQuantity(item)" class="cart-product-quantity-inc" >
                  +
                </button>   

                <div class="error-text" style="height: 0px; margin-top: -15px; padding-left: 3px; font-size: 12px; letter-spacing: 0px; line-height: 1; width: 200px;">
                  <span *ngIf="(item.cartQuantity<1) || (item.cartQuantity>999)">
                    A mennyiség csak 1000-nél kisebb pozitív egész szám lehet!
                  </span>
                </div>                
              </div>
              
              <div class="cart-product-sumprice-container" [ngStyle]="{'color': (item.cartQuantity<1) || (item.cartQuantity>999) ? 'red' : '' }">                
               

                <div style="text-align: right; display: inline-block; vertical-align: middle;  width: 100%;  " >              

                  <div style="width: 100%;">                                                        
                    <div  class="cart-product-sumprice" [ngStyle]="{'display': (item.cartQuantity<1) || (item.cartQuantity>999) ? 'none' : 'block' }">            
                      {{ (item.price*item.cartQuantity) | mycurrency:this.cartService.selectedCurrency }}
                    </div>                                                        
                  </div>                  

                  <div style="text-align: right; line-height: 1; color: #444; font-size: 11px; letter-spacing: -0.4px;" *ngIf="item.discountPercent>0" [ngStyle]="{'display': (item.cartQuantity<1) || (item.cartQuantity>999) ? 'none' : 'block' }" >                                        
                      Megtakarítás:<br>{{((item.origPrice-item.price)*item.cartQuantity) | mycurrency:this.cartService.selectedCurrency }}
                  </div>                                           

                </div>  
                

              </div>



              <div class="cart-product-delete-container">
                
                <button mat-fab  class="cart-product-delete-button" (click)="deleteFromCart(item);" color="basic">
                  <i class="material-icons">delete</i>
                </button>  
                <button mat-icon-button class="cart-product-delete-button-mini" (click)="deleteFromCart(item);" color="basic">
                  <i class="material-icons">delete</i>
                </button> 
              
              </div>          
              
            </div>
        
            <div class="cart-item sum">
               <mat-label>
                 <div class="cart-item-sum-label">
                  <mat-icon style="vertical-align: middle;">shopping_cart</mat-icon>
                  Összesen: {{this.cartService.items.length}} fajta termék,
                 </div>                                                    
                 
                 <div class="cart-item-sum-label">                   
                   {{ (this.cartService.sumPrice) | mycurrency:this.cartService.selectedCurrency }} értékben.
                  </div>                 
                 
               </mat-label>
            </div>
            
          </mat-card> 
          
          <div>
            <button class="stepper-next-button"  mat-raised-button color="primary" matStepperNext [disabled]="!this.cartService.cartIsValid()">
              Tovább a szállításhoz
              <mat-icon>navigate_next</mat-icon>            
            </button>
          </div>   
      
      
    </mat-step>

    <mat-step [stepControl]="shippingStepGroup" [editable]="(isEditable)"  >
                    
      <form [formGroup]="shippingStepGroup" name="shippingStepGroup" id="shippingStepGroup">
          <ng-template matStepLabel>
            <span class="stepper-header">Szállítás módja</span>
            <mat-icon class="stepper-header-icon">local_shipping</mat-icon>
          </ng-template>        
          

          <mat-card style="margin-top: 20px;margin-bottom: 20px;"> 
            <mat-radio-group name="shippingGroup" formControlName="shippingGroup">
            <div class="cart-item" *ngFor="let shipping of shippingCosts | async">                      
                <mat-radio-button  (change)="choiseShipping(shipping)" value="{{shipping.type}}">
                  <span class="cart-radio-label">{{ shipping.type }}</span>
                  <span class="cart-radio-label" *ngIf="shipping.price>0" style="color: gray; ">                     
                    + {{ (shipping.price) | mycurrency:this.cartService.selectedCurrency }}
                  </span>               
                </mat-radio-button>              
            </div>
            </mat-radio-group>

            <div class="cart-item sum" >                        
                <mat-label style="float: right;">
                  Fizetendő:                   
                  {{ ((this.cartService.sumPrice+this.cartService.shipping.price)) | mycurrency:this.cartService.selectedCurrency }}
                </mat-label>                        
            </div>                  
          </mat-card>

          <div>
            <button class="stepper-next-button" mat-button mat-raised-button color="basic" matStepperPrevious>
              <mat-icon class="cart-back-button-icon">navigate_before</mat-icon>
              <span class="cart-back-button-text">Vissza a kosárhoz</span>
            </button>
            <button class="stepper-next-button" mat-button mat-raised-button color="primary" matStepperNext [disabled]="shippingStepGroup.controls['shippingGroup'].value==''">
              Tovább a fizetési módhoz
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>

      </form>
    </mat-step>

    <mat-step [stepControl]="paymentStepGroup" [editable]="(isEditable)"  >
      <form [formGroup]="paymentStepGroup">
        <ng-template matStepLabel>
          <span class="stepper-header">Fizetés módja</span>
          <mat-icon class="stepper-header-icon">payment</mat-icon>
        </ng-template>  
           

        <mat-card style="margin-top: 20px;margin-bottom: 20px; padding-bottom: 30px;"> 
          <mat-radio-group name="paymentGroup" formControlName="paymentGroup"> 
            <div class="cart-item" *ngFor="let payment of payments | async">                               
              <mat-radio-button (change)="choisePayment(payment)" value="{{payment.type}}">
                <span class="cart-radio-label">{{ payment.type }}</span>
              </mat-radio-button>            
            </div>
          </mat-radio-group>     

          <div class="cart-item sum" >                        
            <mat-label style="float: right;">
              Fizetendő:               
              {{ ((this.cartService.sumPrice+this.cartService.shipping.price)) | mycurrency:this.cartService.selectedCurrency }}
            </mat-label>                        
          </div>       
        </mat-card>  
          

        <div style="margin-top: 20px;">
          <button class="stepper-next-button" mat-button mat-raised-button color="basic" matStepperPrevious>
            <mat-icon class="cart-back-button-icon">navigate_before</mat-icon>
            <span class="cart-back-button-text">Vissza a szállításhoz</span>
          </button>
          <button class="stepper-next-button" mat-button mat-raised-button color="primary" matStepperNext [disabled]="paymentStepGroup.controls['paymentGroup'].value==''">
            Tovább a cím adatokhoz
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>

      </form>
    </mat-step>

    <mat-step [stepControl]="customerStepGroup" [editable]="(isEditable)" >
      <form [formGroup]="customerStepGroup">

        <ng-template matStepLabel>
          <span class="stepper-header">Cím adatok</span>
          <mat-icon class="stepper-header-icon">contact_mail</mat-icon>
        </ng-template>  

       <mat-card style="margin-top: 20px;margin-bottom: 20px; padding-bottom: 40px;"> 

        <mat-form-field appearance="standard"> 
          <mat-label for="name">
            Vásárló neve  
          </mat-label>                 
          <input matInput id="name" type="text" formControlName="name" name="name" minlength="5" maxlength="100" required>            
          <mat-icon color="warn" *ngIf="customerStepGroup.controls['name'].invalid && customerStepGroup.controls['name'].touched" matSuffix>warning</mat-icon>
          <mat-hint *ngIf="customerStepGroup.controls['name'].invalid && customerStepGroup.controls['name'].touched">
            <span class="error-text" *ngIf="customerStepGroup.controls['name'].value==''">
              kitöltése kötelező
            </span> 
            <span class="error-text" *ngIf="customerStepGroup.controls['name'].value!=''">
              minimum 5 és maximim 100 karakter lehet
            </span> 
          </mat-hint>  
        </mat-form-field>
        <mat-form-field  appearance="standard">
          <mat-label for="address">
            Szállítási cím
          </mat-label>          
          <input matInput id="address" type="text" formControlName="address" name="address" minlength="5" maxlength="100" required>         
          <mat-icon color="warn" *ngIf="customerStepGroup.controls['address'].invalid && customerStepGroup.controls['address'].touched" matSuffix>warning</mat-icon>
          <mat-hint *ngIf="customerStepGroup.controls['address'].invalid && customerStepGroup.controls['address'].touched">
            <span class="error-text" *ngIf="customerStepGroup.controls['address'].value==''">
              kitöltése kötelező!
            </span> 
            <span class="error-text" *ngIf="customerStepGroup.controls['address'].value!=''">
              minimum 5 és maximim 100 karakter lehet!
            </span> 
          </mat-hint>
        </mat-form-field>
        <mat-form-field  appearance="standard"> 
          <mat-label for="phone">
            Telefonszám
          </mat-label>  
          <input matInput id="phone" type="text" formControlName="phone" name="phone" pattern="((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})" required>
          <mat-icon color="warn" *ngIf="customerStepGroup.controls['phone'].invalid && customerStepGroup.controls['phone'].touched" matSuffix>warning</mat-icon>
          <mat-hint *ngIf="customerStepGroup.controls['phone'].invalid && customerStepGroup.controls['phone'].touched">
            <span class="error-text" *ngIf="customerStepGroup.controls['phone'].value==''">
              kitöltése kötelező! 
            </span>
            <span class="error-text" *ngIf="customerStepGroup.controls['phone'].value!=''">
              hibás formátum, elfogadott pl.: +36123456789, 36123456789, 06123456789 stb..
            </span>
          </mat-hint>
        </mat-form-field> 
        <mat-form-field  appearance="standard">
          <mat-label for="email">            
            E-mail cím
          </mat-label>                    
          <input matInput id="email" type="email" formControlName="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required> 
          <mat-icon color="warn" *ngIf="customerStepGroup.controls['email'].invalid && customerStepGroup.controls['email'].touched" matSuffix>warning</mat-icon>
          <mat-hint *ngIf="customerStepGroup.controls['email'].invalid && customerStepGroup.controls['email'].touched">
            <span class="error-text" *ngIf="customerStepGroup.controls['email'].value==''">
              kitöltése kötelező! 
            </span>
            <span class="error-text" *ngIf="customerStepGroup.controls['email'].value!=''">
              hibás formátum elfogadott pl.: valaki@valami.com
            </span>
          </mat-hint>   
        </mat-form-field>                   
        
       </mat-card>
        
        <div *ngIf="this.cartService.items.length>0" style="margin-top: 20px;">
          <button class="stepper-next-button" mat-button mat-raised-button color="basic" matStepperPrevious>
            <mat-icon class="cart-back-button-icon">navigate_before</mat-icon>
            <span class="cart-back-button-text">Vissza a fizetési módhoz</span>
          </button>
          <button class="stepper-next-button" mat-button mat-raised-button (click)="isEditable=false; onSubmit();" color="primary" matStepperNext [disabled]="customerStepGroup.invalid">
            Rendelés befejezése
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>   

      </form>
    </mat-step>
    

  </mat-horizontal-stepper>

  
  <mat-card style="margin-top: 20px;margin-bottom: 20px; padding-bottom: 20px;" *ngIf="(this.cartService.items.length==0)">         
    <mat-icon color="warn" matSuffix >warning</mat-icon>
    <h2>Az ön kosara üres</h2>  
    <mat-label>
      <a [title]="'vissza a termék listához'" routerLink="">
        Tovább vásárolok, vissza a termék listához ...
      </a>
    </mat-label>
  </mat-card>

 