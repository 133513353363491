
<div class="container top50">

  <mat-spinner *ngIf="this.loadTable==true"  class="table-spinner"></mat-spinner> 

  <h1>Fizetési módok</h1>
  
  <mat-form-field>
    <mat-label>Keresés</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ide írja le a keresett kifejezést..." #input>
  </mat-form-field>
  
  <table mat-table [dataSource]="payments" matSort class="mat-elevation-z8">
    
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> # </th>
      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>
  
  
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Megnevezés </th>
      <td mat-cell *matCellDef="let row"> {{row.type}} </td>
    </ng-container>
       
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nincs a keresési feltételnek megfelelő adat "{{input.value}}"</td>
    </tr>

  </table>
  
  <mat-paginator #pagination [pageSizeOptions]="[ 25, 50, 100]"></mat-paginator>

</div>