import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MatSliderModule } from '@angular/material/slider';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { registerLocaleData } from '@angular/common';
import   localeHu from '@angular/common/locales/hu';
/*import   localeEn from '@angular/common/locales/en';
import   localeEu from '@angular/common/locales/eu';*/
import { CartComponent } from './cart/cart.component';
import { ShippingComponent } from './shipping/shipping.component';
import { PaymentComponent } from './payment/payment.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { YesnoDialogComponent } from './yesno-dialog/yesno-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ScrolltopComponent } from './scrolltop/scrolltop.component';
import { ProductListSalesComponent } from './product-list-sales/product-list-sales.component';
import { HomeComponent } from './home/home.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { IvyGalleryModule } from 'angular-gallery';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryProductListComponent } from './category-product-list/category-product-list.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ProductSearchResultComponent } from './product-search-result/product-search-result.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { TopTopMenuComponent } from './top-top-menu/top-top-menu.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { ProductDetailsTopMenuComponent } from './product-details-top-menu/product-details-top-menu.component';
import { OnlyPositiveNumbersDirective } from './directives/only-positive-numbers.directive';
import { CartMenuComponent } from './cart-menu/cart-menu.component';
import { MycurrencyPipe } from './mycurrency.pipe';


registerLocaleData(localeHu, 'hu');
/*registerLocaleData(localeEn, 'en');
registerLocaleData(localeEu, 'eu');*/



@NgModule({
  declarations: [
    AppComponent,    
    ProductListComponent,
    ProductDetailsComponent,
    CartComponent,
    ShippingComponent,
    PaymentComponent,
    TopMenuComponent,
    InfoDialogComponent,
    YesnoDialogComponent,   
    BreadcrumbComponent,
    ScrolltopComponent,
    ProductListSalesComponent,
    HomeComponent,
    CategoryListComponent,
    CategoryProductListComponent,
    ProductSearchComponent,
    ProductSearchResultComponent,
    ContactComponent,
    ProductComponent,
    TopTopMenuComponent,
    CategoryMenuComponent,
    ProductDetailsTopMenuComponent,
    OnlyPositiveNumbersDirective,
    CartMenuComponent,
    MycurrencyPipe,      
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule, 
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatSnackBarModule,
    MatStepperModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgxSliderModule,    
    IvyGalleryModule,
    MatAutocompleteModule,     
    ],
  providers: [ ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AppModule {
  
  
 }
