<!--<div class="container top128">        -->
  <mat-spinner *ngIf="!pageIsDone()" class="table-spinner"></mat-spinner>        
  <div [ngStyle]="{'display': pageIsDone() ? 'block' : 'none'}">  
  
    <div style="width: 100%; " >
      <app-category-menu [paramIsVisible]="true" (isDoneEmit)="getCategoryListIsDone($event)" ></app-category-menu>
    </div>  
  
    <app-product-list [paramCategoryId]="0" [paramSearchText]="''" [paramCurrency]="this.cartService.selectedCurrency" (isDoneEmit)="getProductListIsDone($event)"></app-product-list>          
  </div>  
<!--</div>  -->

  



