import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber} from '@angular/common';
import { currencyType } from './models';

@Pipe({
  name: 'mycurrency'
})
export class MycurrencyPipe implements PipeTransform {  
  transform(value: number,  currency: currencyType): unknown {           
    return formatNumber(value/currency.switchValue, 'hu','0.0-'+currency.decimal) +' '+currency.symbol;
  }
}

