
  <div>
    <app-breadcrumb [categoryId]="categoryDetail.id"></app-breadcrumb>      
  </div>

  <!--
  <div *ngIf="(paramCategoryId==0) && (this.categories.length>0)"  style="padding: 10px 10px 0px 10px; margin-bottom: 0px;">
    <h2 style="margin: 0px !important; color: rgba(0,0,0,.83); font-weight: 300;">
      <mat-icon color="primary" style="vertical-align: text-bottom; ">style</mat-icon>
      &nbsp;Termékeink
    </h2> 
  </div>
  -->

 <div>
  <mat-grid-list *ngIf="(this.categories.length>0)" cols="{{screenService.getColCount()}}"  rowHeight="410px" >        
   <div>      
      <mat-grid-tile *ngFor="let category of categories" >       
        <div class="product-desc-container" style="margin-top: 20px;"  [routerLink]="['/categories', category.id]" >
          <mat-card  class="my-card-cat" >

            <mat-card-header style="height: 60px; background-color: #EBEBEB; padding:8px 0px 0px 8px; margin-bottom:8px">          
              <!--
              <div mat-card-avatar class="category-header-image" style="background-image: url('{{category.medias[0].file_url}}');" ></div> 
              -->
              <mat-card-title class="cat-card-title">   
                <a style="color: rgba(0,0,0,.87);">
                  {{category.name}} 
                </a>    
              </mat-card-title>
              <mat-card-subtitle style="height: 20px; line-height: 1;">{{category.title}}</mat-card-subtitle>
            </mat-card-header>
         
            <div class="product-img-container" *ngIf="category.medias.length>0"  >       
              <img class="product-img" src="{{ category.medias[0].file_url }}" />  
            </div>
         
            <mat-card-content style="margin-top: 12px;  padding: 18px; background-color: #EBEBEB;">
              <p style="font-size: 13px !important; ">
                {{ category.description }}            
              </p>
            </mat-card-content>  

          </mat-card> 
        </div>    
      </mat-grid-tile>
    </div>
  </mat-grid-list>     
 </div>



 
