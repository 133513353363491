<form class="product-search-form">        
    <mat-form-field class="product-search-field" appearance="outline" >           
      <mat-label style="color: #3f51b5;">keres valamit?</mat-label>
      <input type="text"                          
             placeholder="Ide írja le ..."   
             matInput
             [formControl]="searchControl"
             [matAutocomplete]="auto"
             (keyup.enter)="keyUpEnter()"                     
             style="color: #3f51b5"
             maxlength="100">             
      <mat-icon matSuffix (click)="sendSearchText()" style="cursor: pointer; transform: scale(1.4); color: #3f51b5;" >search</mat-icon>       
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option class="search-option-container" (onSelectionChange)="clearSearchControlValue(option)" *ngFor="let option of filteredOptions | async" [value]="option.name+' '+option.sku" [routerLink]="['/products', option.id]" >          
         
          <div class="search-option-image-container" *ngIf="option.medias.length>0"  >       
            <img class="option-img" src="{{ option.medias[0].file_url }}" [routerLink]="['/products', option.id]" width="40px" height="40px" />  
          </div> 
    
          <div class="search-option-productname-container">           
            {{option.name}} 
            <br>
            <span style="font-size: 10px; color: rgba(0,0,0,.57);">{{option.sku}}</span>
          </div> 

          <div class="search-option-image-1-container" *ngIf="option.medias.length>0"  >       
            <img class="option-img" src="{{ option.medias[0].file_url }}" [routerLink]="['/products', option.id]" width="40px" height="40px" />  
          </div> 

          <div class="search-option-percent-container">           
            <div *ngIf="option.discountPercent>0" style="background-color: #f44336; color: white; vertical-align: middle; display: inline-block; margin-right: 4px; margin-left: 4px; width: 30px; height: 30px; line-height: 30px; text-align: center; font-size: 9px; border-radius: 90px; box-shadow: 1px 1px 3px rgb(0 0 0 / 54%); ">
              -{{option.discountPercent}}%
            </div>  
          </div> 
          
          <div class="search-option-price-container">            
            <div *ngIf="option.discountPercent>0" style="width: 100%;  font-size: 11px; color:red;text-decoration:line-through">
              <span style="color:black">               
                {{ (option.origPrice) | mycurrency:this.paramCurrency }}            
              </span>
            </div>
            <div>
              {{ (option.price) | mycurrency:this.paramCurrency }}            
            </div>
          </div>          

        </mat-option>
      </mat-autocomplete>     
    </mat-form-field>   
</form>
