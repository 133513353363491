import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../common.service';
import { ScreenService } from '../screen.service';
import { categoryType } from '../models';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.css']
})
export class CategoryMenuComponent implements OnInit {

  categories: categoryType[] = [];    
  categoriesFiltered: categoryType[] = [];
  subCategoriesFiltered: categoryType[] = [];  
  selectedCategoryId:number=0;
  selectedCategory: categoryType=this.commonService.setEmptyCategoryData();
  @Output() isDoneEmit = new EventEmitter<boolean>();  
  @Input() paramIsVisible!: boolean;   

  constructor(public commonService: CommonService,
              public screenService: ScreenService,) { }

  setSubCategory(category:categoryType) {  
    var id:number=category.id;
    this.selectedCategoryId=id;
    this.selectedCategory=category;   
    this.subCategoriesFiltered=this.categories.filter(function isMainCat (a) {        
      return a.parentId==id;
    });
  }

  

  ngOnInit(): void {    
    this.isDoneEmit.emit(false);  
    this.commonService.getAllCategories().subscribe((categories: categoryType[]) => {
      this.categories = categories;           
      
      this.categoriesFiltered=this.categories.filter(function isMainCat (a) {        
        return a.parentId==0;
      });     

      this.isDoneEmit.emit(true);  
   });

  }

}
