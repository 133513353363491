  <mat-sidenav-container class="sidenav-container">   

    <mat-sidenav #drawer class="sidenav" fixedInViewport  [attr.role]="'dialog'" [mode]="'over'" [opened]="false" style="left: 0px; width: 330px; " >


      <div style="margin-top: 14px; width: 100%; text-align: center; "> 
        <div>
          <h1 style="color: #3f51b5; font-weight: 900; font-size: 33px;">
            <mat-icon color="accent" style="vertical-align: middle; transform: scale(1.2);">important_devices</mat-icon>
            <span style="margin-left: 14px;">{{this.commonService.title}}</span>
            <span style="color: #ff4081; margin-left: 5px; opacity: 0.8;">{{this.commonService.title1}}</span>
            <mat-icon color="basic" style="cursor: pointer; margin-left: 12px; vertical-align: middle; " (click)="drawer.close()">cancel</mat-icon>
          </h1>
        </div>
      </div>   
     
     

      <mat-nav-list style="margin-bottom: 0px; padding-bottom: 0px; padding-top: 0px; ">   
        <div *ngFor="let category of categoriesFiltered" >          
          <a  mat-list-item style=" display: block; color: #3f51b5; font-size: 19px !important; padding-left: 20px; padding-right: 10px;"  [routerLink]="['/categories', category.id]" (click)="drawer.close()" >
            <img class="option-img" style="display: inline;" aria-hidden [src]="category.medias[0].file_url" height="40px" width="40px">      
            {{category.name}}
          </a>      
          
          <div *ngFor="let subCategory of subCategoriesFiltered">          
            <a  *ngIf="subCategory.parentId==category.id"  mat-list-item style="display: block; height: 20px; padding-left: 35px; font-size: 15px; color: #333; padding-left: 65px; padding-right: 10px;"  [routerLink]="['/categories', subCategory.id]" (click)="drawer.close()" >              
              {{subCategory.name}}
            </a>  
          </div>       
        </div>
      </mat-nav-list>
    
     
     
      <mat-nav-list [routerLink]="['/sales']" (click)="drawer.close()">        
        <a  mat-list-item style="color: #f44336; display: block; padding-left: 28px; padding-right: 10px; font-size: 19px !important;">
          <mat-icon  color="warn" style="cursor: pointer; vertical-align: text-top; margin-right: 10px; ">stars</mat-icon>
          Akciós termékeink        
        </a>
      </mat-nav-list>

      
      <mat-nav-list  [routerLink]="['/contact']" (click)="drawer.close()">        
        <a  mat-list-item style="color: #3f51b5; display: block; padding-left: 28px; padding-right: 10px; font-size: 19px !important;">
          <mat-icon  style="color: #3f51b5; cursor: pointer; vertical-align: text-top; margin-right: 10px; ">perm_phone_msg</mat-icon>
          Ügyfélszolgálat        
        </a>
      </mat-nav-list>


     
      

      <div style="display: inline-flex; padding-left: 26px;  margin-left: 10px; margin-right: 0px; padding-top: 12px;">        
                
          <button mat-icon-button [matMenuTriggerFor]="menu" title="Valuta neme" style="font-size: 19px !important; font-weight: 300; width: 100%; text-align: right;">                
            <mat-icon class="flag-icon-{{this.cartService.selectedCurrency.flag}}" style="vertical-align: text-top; margin-left: 8px; margin-right: 11px;"></mat-icon>              
            <span style="color: #3f51b5; vertical-align: middle; font-size: 19px; font-weight: normal; ">Valutanem - {{this.cartService.selectedCurrency.iso3Code}}</span>
          </button>
    
          
          <mat-menu #menu="matMenu" >                
            <button mat-menu-item *ngFor="let currency of this.cartService.currencies" (click)="onChoiseCurrency(currency)">
              <mat-icon class="flag-icon-{{currency.flag}}"></mat-icon>
              {{currency.iso3Code}} - {{currency.symbol}}      
              <span *ngIf="!currency.default">
                = {{currency.switchValue}} {{this.cartService.currencies[0].symbol}}
              </span>
            </button>       
          </mat-menu>
      </div>  

    </mat-sidenav>    
    <mat-sidenav-content class="sidenav-content">      
   
      <mat-toolbar class="product-details-top-toolbar" color="primary" [ngStyle]="{'top': showProductDetailsTopMenu && popUpProductDetailsTopMenu ? '-2px' : '-100px' }" >        
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button          
          (click)="drawer.toggle()"          
          class="toolbar-swmenu-button"
          [ngStyle]="{'top': ((notFixed) || (screenService.screenWidth<=652)) ? '8px' : '-100px' , 'position': (screenService.screenWidth<=652) && (!scroll88px)  ? 'absolute' : 'fixed', 'background-color': (screenService.screenWidth<=640) && (scroll88px) ? '#3f51b5' : 'white'}"
          >
          <mat-icon aria-label="Side nav toggle icon" [ngStyle]="{'color': (screenService.screenWidth<=640) && (scroll88px) ? 'white' : '#3f51b5'}">menu</mat-icon>
        </button> 
        <app-product-details-top-menu class="product-details-top-menu" [paramCurrency]="this.cartService.selectedCurrency" [paramIsVisible]="showProductDetailsTopMenu" [paramProduct]="this.commonService.currentProductDetails"></app-product-details-top-menu>                
      </mat-toolbar>               


      <mat-toolbar *ngIf="this.loadCartFromLocalStorageIsDone && this.cartService.loadCurrencyFromLocalStorageIsDone" color="primary" class="top-top-toolbar"  [ngStyle]="{'border-bottom': notFixed ? 'solid 2px #3f51b5' : 'solid 2px white' }" >        
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button          
          (click)="drawer.toggle()"          
          class="toolbar-swmenu-button"
          [ngStyle]="{'top': ((notFixed) || (screenService.screenWidth<=652)) ? '8px' : '-100px' , 'position': (screenService.screenWidth<=652) && (!scroll88px)  ? 'absolute' : 'fixed', 'background-color': (screenService.screenWidth<=640) && (scroll88px) ? '#3f51b5' : 'white'}"
          >
          <mat-icon aria-label="Side nav toggle icon" [ngStyle]="{'color': (screenService.screenWidth<=640) && (scroll88px) ? 'white' : '#3f51b5'}">menu</mat-icon>
        </button> 
        <app-top-top-menu style="width: 100%; padding-right: 0px; width: 97%; margin-right: 3%; margin-left: 3%;"></app-top-top-menu>                        
      </mat-toolbar>        

      <mat-toolbar *ngIf="this.loadCartFromLocalStorageIsDone && this.cartService.loadCurrencyFromLocalStorageIsDone" color="primary" class="top-toolbar" [ngStyle]="{'top': notFixed ? '0px' : '64px' }">                        
        <app-top-menu class="top-toolbar-menu"></app-top-menu>                              
      </mat-toolbar>       
      
      <div class="container top128">
        <router-outlet></router-outlet>           
      </div>
    
    </mat-sidenav-content>

  </mat-sidenav-container>

  <app-scrolltop></app-scrolltop>

  